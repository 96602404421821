
import Grid from '@mui/material/Grid';
import { Divider, colors, Container, Skeleton } from '@mui/material';
import {
    CardContent
} from "../../../App/Apps/miner/styles/Dashboardpanel";

import { motion } from 'framer-motion';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';
import { Icon } from '@mui/material';
import useAppState from "../../../../store/appState/useAppState";
import React, { useState, useEffect } from "react";
import { scanAssets, faucet as faucetAPI, startProxy, testLocalServer, getIPaddress, initListenState, getAllNodes } from '../../../../API/index';
import { logger } from '../../logger';
import CircularProgress from '@mui/material/CircularProgress';
import type { nodes_info } from './SaasNodes';
import { useIntl } from "react-intl";
import Stack from '@mui/material/Stack';
import { Tabs, Tab, Button } from '@mui/material-next';
import AccelDial from './speedMater';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChrome, faFirefoxBrowser } from '@fortawesome/free-brands-svg-icons';
import chromeIng from './assets/images/chrome-setup.png';
import fireFox from './assets/images/screen1.png';
import iOSSetup from './assets/images/iOS.png';
import androidSetup from './assets/images/android-setup.png';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import macOSSetup from './assets/images/MacOS.png';
import winSetupImg from './assets/images/winSetup.png';
import WindowSharpIcon from '@mui/icons-material/WindowSharp';
import { loadCSS } from 'fg-loadcss';
import { getWorkerService } from '../../../../services/workerService/workerService';
import { useTheme } from "styled-components";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as ConetAvatar } from "../../../../assets/images/con-avatar.svg";
import { ReactComponent as First } from "../../../../assets/icons/first.svg";
import { useRefreshStore } from "../../../../API/useRefreshStore";
import { CopyToClipboard } from '../../../../utilities/utilities';

const ChromeImg = styled.img`
	width: 50%;
`

const IOSImg = styled.img`
	width: 100%;
`

const OneLineText = styled.span`
`
interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <Grid item xs={12}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index &&
                children
            }
        </Grid>
    )
}

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const ChromeSetup = () => {
    const intl = useIntl()
    return (
        <>
            <Typography variant="body1" sx={{ fontWeight: '900', textAlign: 'center', color: colors.lightGreen[400], paddingRight: '0.5rem' }}>
                {intl.formatMessage({ id: 'platform.proxy.chrome.detail' })}
            </Typography>
            <Button size="large" variant="outlined" sx={{ fontFamily: 'inherit' }}
                onClick={() => window.open(`https://chromewebstore.google.com/detail/conet-platform/pkhkeliikkihkaolfcogfcbjmbehockd`)}
            >
                <Typography variant="h6" sx={{ fontWeight: '500', textAlign: 'center' }}>
                    {intl.formatMessage({ id: 'platform.proxy.featureArea8Item.chrome.extensions' })}
                </Typography>
            </Button>
            <ChromeImg src={chromeIng} />
        </>
    )
}

const FireFoxSetup = () => {
    const intl = useIntl()
    return (
        <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ width: '100%', padding: '2rem 0 0 0' }}>
            <Typography variant="body1" sx={{ fontWeight: '900', textAlign: 'center', color: colors.lightGreen[400], paddingRight: '0.5rem' }}>
                {intl.formatMessage({ id: 'platform.proxy.firefox.detail' })}
            </Typography>
            <ChromeImg src={fireFox} />
        </Stack>
    )
}

const AndroidSetup = () => {

    const [myLocalIpaddress, setMyLocalIpaddress] = useState('')
    const intl = useIntl()
    useEffect(() => {

        const fetchData = async () => {
            if (!active) { return }
            const [, ip] = await getIPaddress()
            const _ip = ip[0].ip
            setMyLocalIpaddress(_ip)
        }

        let active = true
        fetchData()
        return () => { active = false }
    }, [])
    return (
        <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ width: '100%', padding: '2rem 0 0 0' }}>
            <Typography variant="body1" sx={{ fontWeight: '900', textAlign: 'center', color: colors.lightGreen[400], paddingRight: '0.5rem' }} component="span">

                {intl.formatMessage({ id: 'platform.proxy.setup.andOther' })}
                const leaderboardStorage = useState(useRefreshStore.getState().leaderboards)

            </Typography>
            <IOSImg src={androidSetup} />
            <OneLineText>
                <Typography variant="body1" sx={{ fontWeight: '900', textAlign: 'center', color: colors.lightGreen[400], paddingRight: '0.5rem' }} component="span">
                    PAC web address:
                </Typography>
                <Typography variant="body1" sx={{ textAlign: 'center' }} component="span">
                    http://{myLocalIpaddress}:3003/pac
                </Typography>
            </OneLineText>
        </Stack>
    )
}

const MacOSSetup = () => {

    const [myLocalIpaddress, setMyLocalIpaddress] = useState('')
    const intl = useIntl()
    useEffect(() => {

        const fetchData = async () => {
            if (!active) { return }
            const [, ip] = await getIPaddress()
            const _ip = ip[0].ip
            setMyLocalIpaddress(_ip)
        }

        let active = true
        fetchData()
        return () => { active = false }
    }, [])
    return (
        <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ width: '100%', padding: '2rem 0 0 0' }}>
            <Typography variant="body1" sx={{ fontWeight: '900', textAlign: 'center', color: colors.lightGreen[400], paddingRight: '0.5rem' }} component="span">

                {intl.formatMessage({ id: 'platform.proxy.setup.macosOther' })}

            </Typography>
            <IOSImg src={macOSSetup} />

            <Typography variant="body1" sx={{ fontWeight: '900', textAlign: 'center', color: colors.lightGreen[400], paddingRight: '0.5rem' }} component="span">
                Automatic proxy configuration URL:
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }} component="span">
                http://{myLocalIpaddress}:3003/pac
            </Typography>

        </Stack>
    )
}

const WinSetup = () => {

    const [myLocalIpaddress, setMyLocalIpaddress] = useState('')
    const intl = useIntl()
    useEffect(() => {

        const fetchData = async () => {
            if (!active) { return }
            const [, ip] = await getIPaddress()
            const _ip = ip[0].ip
            setMyLocalIpaddress(_ip)
        }

        let active = true
        fetchData()
        return () => { active = false }
    }, [])
    return (
        <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ width: '100%', padding: '2rem 0 0 0' }}>
            <Typography variant="body1" sx={{ fontWeight: '900', textAlign: 'center', color: colors.lightGreen[400], paddingRight: '0.5rem' }} component="span">

                {intl.formatMessage({ id: 'platform.proxy.setup.winOther' })}

            </Typography>
            <IOSImg src={winSetupImg} />

            <Typography variant="body1" sx={{ fontWeight: '900', textAlign: 'center', color: colors.lightGreen[400], paddingRight: '0.5rem' }} component="span">
                Script address:
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }} component="span">
                http://{myLocalIpaddress}:3003/pac
            </Typography>

        </Stack>
    )
}

const IOS = () => {
    const [myLocalIpaddress, setMyLocalIpaddress] = useState('')
    const intl = useIntl()
    useEffect(() => {

        const fetchData = async () => {
            if (!active) { return }
            const [, ip] = await getIPaddress()
            const _ip = ip[0].ip
            setMyLocalIpaddress(_ip)
        }

        let active = true
        fetchData()
        return () => { active = false }
    }, [])

    return (
        <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ width: '100%', padding: '2rem 0 0 0' }}>
            <Typography variant="body1" sx={{ fontWeight: '900', textAlign: 'center', color: colors.lightGreen[400], paddingRight: '0.5rem' }} component="span">

                {intl.formatMessage({ id: 'platform.proxy.setup.iOSOther' })}

            </Typography>
            <IOSImg src={iOSSetup} />
            <OneLineText>
                <Typography variant="body1" sx={{ fontWeight: '900', textAlign: 'center', color: colors.lightGreen[400], paddingRight: '0.5rem' }} component="span">
                    URL:
                </Typography>
                <Typography variant="body1" sx={{ textAlign: 'center' }} component="span">
                    http://{myLocalIpaddress}:3003/pac
                </Typography>
            </OneLineText>



        </Stack>
    )
}


const FeatureArea8ItemNew = () => {
    const {
        isProxyStart,
        setIsProxyStart
    } = useAppState()
    const currentProfile = () => {
        const workerService = getWorkerService()
        if (workerService.data.status === 'LOCKED') {
            return null
        }
        // const index = workerService.data.profiles.findIndex((n:any) => {
        // 	return n.isPrimary
        // })

        return workerService.data
    }
    const profile = currentProfile()
    const [CONET_Balance, setCONET_Balance] = useState(profile.tokens.conet.balance)
    const [nodes, setNodes] = useState<nodes_info[]>([])
    const [value, setValue] = React.useState(0)
    const [value1, setValue1] = React.useState(0)
    const [animei, setAnimei] = useState<'left' | 'right'>('left')
    const [animei1, setAnimei1] = useState<'left' | 'right'>('left')
    const [faucetProcess, setFaucetProcess] = useState(false)
    const [faucetError, setFaucetError] = useState(false)
    const [regionProgress, setRegionProgress] = useState(false)
    const [showConfirm, setShowConfirm] = useState(true)
    const [startProxyError, setStartProxyError] = useState(false)
    const [conetLoading, setConetLoading] = useState(false)
    const [regions, setRegions] = useState<string[]>([])

    const showStartProxy = () => (parseFloat(CONET_Balance) > 0 || nodes.length > 0) && !isProxyStart

    useEffect(() => {

        const fetchData = async () => {
            if (!active) { return }
            const node = loadCSS(
                'https://use.fontawesome.com/releases/v6.5.1/css/all.css',
                // Inject before JSS
                //@ts-ignore
                document.querySelector('#font-awesome-css') || document.head.firstChild
            )



            initListenState('cntp-balance', data => {
                setConetLoading(false)
                logger(`getCONETBalance SUCCESS`, data)
                setCONET_Balance(data.CONET_Balance)
            })
            await scanAssets()
            const [succes, nodes] = await getAllNodes()
            if (succes === 'SUCCESS') {
                const k = nodes[0].node
                //	@ts-ignore
                const uuu = Object.groupBy(k, n => n.country)
                const regions = Object.keys(uuu)
                setRegions(regions)
            }



            // setCONET_Balance(data[1].toString())
            // const _nodes: nodes_info[] = data[2]
            // if (_nodes.length > 0) {
            //     setNodes(_nodes)
            //     _startProxy()
            // }

            return () => {
                active = false
                node.parentNode!.removeChild(node)
            }
        }

        let active = true
        fetchData()
        return () => { active = false }
    }, [])

    const _startProxy = async () => {
        if (!isProxyStart) {
            const [status] = await startProxy()
            setRegionProgress(false)
            if (status !== 'SUCCESS') {
                return setStartProxyError(true)
            }

            return setIsProxyStart(true)
        }

    }


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        const old = value
        setValue(newValue)
        if (newValue > old) {
            return setAnimei('right')
        }
        setAnimei('left')
    }

    const handleChange1 = (event: React.SyntheticEvent, newValue: number) => {
        const old = value1
        setValue1(newValue)
        if (newValue > old) {
            return setAnimei1('right')
        }
        setAnimei1('left')
    }

    const regionConfirm = async () => {
        setRegionProgress(true)
        if (nodes.length > 0) {
            return _startProxy()
        }
    }

    const faucetClick = async () => {

        setFaucetProcess(true)
        setFaucetError(false)
        const [status, data] = await faucetAPI()
        setFaucetProcess(false)
        if (status !== 'SUCCESS' || !data) {
            setFaucetError(true)
            setTimeout(() => {
                setFaucetError(false)
            }, 5000)
            return logger('LaunchPage Error', 'useEffect fetchData getCONETBalance had no SUCCESS')
        }

        setCONET_Balance(data[1])

    }

    const intl = useIntl()


    const GetFaucet = () => {
        return (
            <Grid container sx={{ textAlign: 'center', width: '100%' }} spacing={5}>

                <Grid item xs={12} sx={{ textAlign: 'center', width: '100%' }}>

                    <Stack direction="column" alignItems="center" spacing={2} sx={{}}>
                        <Typography variant="h5" sx={{ fontWeight: '700', textAlign: 'center' }}>
                            {intl.formatMessage({ id: 'platform.proxy.featureArea8Item.step1' })}
                        </Typography>
                        <Typography variant="h6" sx={{ textAlign: 'center' }}>
                            {intl.formatMessage({ id: 'platform.proxy.featureArea8Item.step1.CONETbalance' })}
                        </Typography>
                        {
                            conetLoading &&
                            <Icon baseClassName="fa-solid" className="fa-circle-notch fa-spin" sx={{ fontSize: 20, color: '#6e7b63' }} />
                        }
                        {
                            !conetLoading &&
                            <Box>
                                <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: '600', padding: '0rem 0 1rem 0' }}>
                                    {CONET_Balance}
                                </Typography>
                                {
                                    faucetProcess &&
                                    <Box sx={{ display: 'block', textAlign: 'center', width: '100%' }}>
                                        <CircularProgress color='success' disableShrink />
                                    </Box>
                                }
                                {
                                    faucetError &&
                                    <Typography variant="h6" sx={{ textAlign: 'center', color: '#ba1a1a' }}>
                                        {intl.formatMessage({ id: 'platform.ProfileDropdown.faucet.error' })}
                                    </Typography>
                                }
                                {
                                    !faucetProcess &&
                                    <Button size="large" variant="outlined" onClick={faucetClick} sx={{ fontFamily: 'inherit', width: '10rem' }}>
                                        {intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.actionFondWallet' })}
                                    </Button>
                                }
                            </Box>

                        }
                    </Stack>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center', width: '100%' }}>
                    <Divider sx={{ width: '50%', margin: 'auto' }} />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center', width: '100%' }}>
                    <Stack direction="column" alignItems="center" spacing={2} sx={{}}>
                        {
                            !isProxyStart && parseFloat(CONET_Balance) === 0 &&
                            <Typography variant="h5" sx={{ fontWeight: '700', textAlign: 'center' }}>
                                {intl.formatMessage({ id: 'platform.proxy.featureArea8Item.step2.cant' })}
                            </Typography>
                        }
                        {
                            showStartProxy() &&
                            <>
                                <Typography variant="h5" sx={{ fontWeight: '700', textAlign: 'center' }}>
                                    {intl.formatMessage({ id: 'platform.proxy.featureArea8Item.step2' })}
                                </Typography>
                                {
                                    regionProgress &&
                                    <Box sx={{ display: 'block', textAlign: 'center', width: '100%' }}>
                                        <CircularProgress color='success' disableShrink />
                                    </Box>
                                }
                                {
                                    !regionProgress &&
                                    <Button size="large" variant="outlined" onClick={regionConfirm} sx={{ fontFamily: 'inherit', width: '10rem' }}>
                                        {intl.formatMessage({ id: 'platform.proxy.FeatureArea.start' })}
                                    </Button>
                                }
                            </>
                        }


                    </Stack>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container direction="column" sx={{ textAlign: 'center', width: '100%' }}>
            {
                isProxyStart &&
                <Grid item sx={{ textAlign: 'center', width: '100%' }}>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                        <Tabs value={value1} onChange={handleChange1}
                            variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile>
                            <Tab label="Chrome" iconPosition="end" icon={<FontAwesomeIcon icon={faChrome} color="#E15241" />} />
                            <Tab label="FireFox" iconPosition="end" icon={<FontAwesomeIcon icon={faFirefoxBrowser} color={colors.orange[900]} />} />
                            <Tab label={<AppleIcon />} iconPosition="end" icon={intl.formatMessage({ id: 'platform.proxy.setup.iOS' })} />
                            <Tab label={<AndroidIcon sx={{ color: '#67AD5B' }} />} iconPosition="end" icon={intl.formatMessage({ id: 'platform.proxy.setup.android' })} />
                            <Tab label={<AppleIcon />} iconPosition="end" icon={intl.formatMessage({ id: 'platform.proxy.setup.macos' })} />
                            <Tab label={<WindowSharpIcon sx={{ color: '#3375D4' }} />} iconPosition="end" icon={intl.formatMessage({ id: 'platform.proxy.setup.win' })} />
                        </Tabs>
                    </Box>

                    <Box>
                        <CustomTabPanel value={value1} index={0}>
                            <Slide direction={animei1} in={value1 === 0} mountOnEnter unmountOnExit>
                                <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ width: '100%', padding: '2rem 0 0 0' }}>
                                    <ChromeSetup />
                                </Stack>
                            </Slide>

                        </CustomTabPanel>

                        <CustomTabPanel value={value1} index={1}>
                            <Slide direction={animei1} in={value1 === 1} mountOnEnter unmountOnExit>
                                <Container>
                                    <FireFoxSetup />
                                </Container>
                            </Slide>
                        </CustomTabPanel>
                        <CustomTabPanel value={value1} index={2}>
                            <Slide direction={animei1} in={value1 === 2} mountOnEnter unmountOnExit>
                                <Container >
                                    <IOS />
                                </Container>
                            </Slide>
                        </CustomTabPanel>
                        <CustomTabPanel value={value1} index={3}>
                            <Slide direction={animei1} in={value1 === 3} mountOnEnter unmountOnExit>
                                <Container sx={{ width: '100%' }}>
                                    <AndroidSetup />
                                </Container>
                            </Slide>
                        </CustomTabPanel>
                        <CustomTabPanel value={value1} index={4}>
                            <Slide direction={animei1} in={value1 === 4} mountOnEnter unmountOnExit>
                                <Container sx={{ width: '100%' }}>
                                    <MacOSSetup />
                                </Container>
                            </Slide>
                        </CustomTabPanel>
                        <CustomTabPanel value={value1} index={5}>
                            <Slide direction={animei1} in={value1 === 5} mountOnEnter unmountOnExit>
                                <Container sx={{ width: '100%' }}>
                                    <WinSetup />
                                </Container>
                            </Slide>
                        </CustomTabPanel>
                    </Box>
                </Grid>
            }
            <Grid item sx={{ textAlign: 'center', width: '100%', alignItems: "center", padding: '2rem 0 2rem 0' }}>
                <Stack direction="row" justifyContent="center" spacing={1} sx={{ width: '100%' }}>
                    {
                        isProxyStart &&
                        <AccelDial />

                    }
                    {
                        isProxyStart &&
                        <AccelDial />

                    }
                </Stack>
            </Grid>
            <Grid item sx={{ textAlign: 'center', width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '3rem' }}>
                    <Tabs value={value} onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile>
                        <Tab label={intl.formatMessage({ id: 'platform.proxy.step1.title' })} {...a11yProps(0)} />
                        <Tab label={intl.formatMessage({ id: 'platform.proxy.subscription.user' })} {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Slide direction={animei} in={value === 0} mountOnEnter unmountOnExit>
                        {GetFaucet()}
                    </Slide>

                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Slide direction={animei} in={value === 1} mountOnEnter unmountOnExit>
                        <Typography variant="h5" sx={{ fontWeight: '900', textAlign: 'center', paddingTop: '2rem', textTransform: 'uppercase' }}>
                            {intl.formatMessage({ id: 'platform.joinUS.forDeveloper.button' })}
                        </Typography>
                    </Slide>
                </CustomTabPanel>

            </Grid>
        </Grid>
    )
}

const LaunchPage = () => {
    const workservice = getWorkerService();
    const [statusListTable, setStatusListTable] = useState<'cntp' | 'referrals'>('cntp')
    const leaderboardRenderer = useRefreshStore.getState().leaderboards
    const { activeProfile } = useAppState();

    const referralsLink = activeProfile
        ? window.location.origin + "/?referral=" + workservice.data?.keyID?.toLowerCase()
        : "";

    const theme = useTheme();
    const {
        locale,
        setlocalDaemon
    } = useAppState()

    useEffect(() => {

        const fetchData = async () => {
            const test = await testLocalServer()
            if (test !== true) {
                setlocalDaemon(false)
            }
        }

        let active = true
        fetchData()
        return () => { active = false }
    }, [])

    return (
        <Stack
            direction={'column'}
            width={'100%'}
            justifyContent={'center'}
            paddingTop={'16px'}
        >
            <Stack
                direction={'column'}
                justifyContent={'center'}
                margin={'auto'}
                p={'20px 16px'}
                alignItems={'center'}
                width={'100%'}
            >

                <Stack width={'100%'}>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        width={'100%'}
                        alignItems={'center'}
                    >
                        <Typography
                            id="welcome"
                            variant="h4"
                            sx={{ fontWeight: "400", fontSize: `24px` }}
                        >
                            {/* {intl.formatMessage({ id: "platform.miner.header.title" })} */}
                            Leaderboard
                            <span style={{ marginLeft: "8px", fontSize: "18px", fontWeight: 600, color: "#9f9fa3" }}>Free Miners</span>
                        </Typography>
                        <Typography
                            sx={{
                                color: theme.ui.colors.text.subtittle,
                                fontWeight: `500`,
                                fontSize: `14px`,
                            }}
                        >
                            Invite your friends to rank up
                        </Typography>
                    </Stack>
                    <>

                        <Box display={'flex'} flexDirection={['column', 'row']} gap={"8px"} justifyContent={'space-between'} marginY={"12px"}>
                            <Stack style={{ width: "100%", border: "1px solid #d5d5d5", padding: '16px', borderRadius: "8px", gap: "16px" }}>
                                <Typography fontSize={14} fontWeight={600} textAlign={'left'}>Epoch</Typography>
                                {leaderboardRenderer?.epoch ?
                                    <Typography fontSize={14} fontWeight={400} textAlign={'right'}>{leaderboardRenderer?.epoch}</Typography>
                                    :
                                    <Skeleton variant="rectangular" height={"16px"} />
                                }
                            </Stack>
                            <Stack style={{ width: "100%", border: "1px solid #d5d5d5", padding: '16px', borderRadius: "8px", gap: "16px" }}>
                                <Typography fontSize={14} fontWeight={600} textAlign={'left'}>Total Online Miners</Typography>
                                {leaderboardRenderer?.totalMiner ?
                                    <Typography fontSize={14} fontWeight={400} textAlign={'right'}>{leaderboardRenderer?.totalMiner} users</Typography>
                                    :
                                    <Skeleton variant="rectangular" height={"16px"} />
                                }
                            </Stack>
                            <Stack style={{ width: "100%", border: "1px solid #d5d5d5", padding: '16px', borderRadius: "8px", gap: "16px" }}>
                                <Typography fontSize={14} fontWeight={600} textAlign={'left'}>Rate</Typography>
                                {leaderboardRenderer?.minerRate ?
                                    <Typography fontSize={14} fontWeight={400} textAlign={'right'}>{parseFloat(leaderboardRenderer?.minerRate).toFixed(6)} $CNTP</Typography>
                                    :
                                    <Skeleton variant="rectangular" height={"16px"} />
                                }
                            </Stack>

                            <Stack
                                onClick={() => CopyToClipboard(referralsLink)}
                                style={{ width: "100%", borderRadius: "8px", gap: "16px", cursor: "pointer" }}>
                                <motion.div
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                                    style={{ display: 'flex', cursor: "pointer", height: "100%" }}
                                >
                                    <CardContent
                                        direction="column"
                                        bg={theme.ui.colors.background.linear}
                                        color={theme.ui.colors.text.inverted}
                                        padding="1rem"
                                        flex="5"
                                        style={{
                                            borderRadius: "8px",
                                            justifyContent: "center",
                                            cursor: "pointer"
                                        }}
                                    // maxH="5.5rem"
                                    >
                                        <Typography style={{ fontWeight: "800", fontSize: "20px" }}>Copy my referral link</Typography>
                                    </CardContent>
                                </motion.div>

                            </Stack>
                        </Box>
                        <Stack
                            direction={'row'}
                            width={'100%'}
                            p='8px 24px'
                            bgcolor={theme.ui.colors.background.tableHeader}
                            color={theme.ui.colors.text.table}
                            borderRadius={'16px'}
                            justifyContent={'space-between'}
                        >
                            <Typography fontSize={14} fontWeight={500} width={'50px'}>Rank</Typography>
                            <Typography fontSize={14} fontWeight={500} width={['160px', '240px']}>User</Typography>
                            <Stack style={{ cursor: "pointer" }} onClick={() => setStatusListTable('referrals')} >
                                <Typography fontSize={14} fontWeight={500} width={'120px'} >Online Referrals</Typography>
                            </Stack>
                            <Stack style={{ cursor: "pointer" }} onClick={() => setStatusListTable('cntp')}>
                                <Typography fontSize={14} fontWeight={500} width={'85px'} textAlign={'right'}>CNTP/second</Typography>
                            </Stack>
                        </Stack>

                        {!(leaderboardRenderer && leaderboardRenderer.free_cntp && leaderboardRenderer.free_referrals) &&
                            <>
                                <Skeleton variant="rectangular" width={'100%'} height={85} style={{ marginTop: '8px' }} />
                                <Skeleton variant="rectangular" width={'100%'} height={85} style={{ marginTop: '8px' }} />
                                <Skeleton variant="rectangular" width={'100%'} height={85} style={{ marginTop: '8px' }} />
                                <Skeleton variant="rectangular" width={'100%'} height={85} style={{ marginTop: '8px' }} />
                                <Skeleton variant="rectangular" width={'100%'} height={85} style={{ marginTop: '8px' }} />
                                <Skeleton variant="rectangular" width={'100%'} height={85} style={{ marginTop: '8px' }} />
                                <Skeleton variant="rectangular" width={'100%'} height={85} style={{ marginTop: '8px' }} />
                                <Skeleton variant="rectangular" width={'100%'} height={85} style={{ marginTop: '8px' }} />
                                <Skeleton variant="rectangular" width={'100%'} height={85} style={{ marginTop: '8px' }} />
                            </>
                        }

                        {statusListTable === 'cntp' ? (
                            <>
                                {leaderboardRenderer && leaderboardRenderer.free_cntp && leaderboardRenderer.free_referrals && (
                                    <Stack width={'100%'} px='24px' fontSize={14}>
                                        {leaderboardRenderer?.free_cntp.map((value, index) => {
                                            return (
                                                <Stack
                                                    key={index}
                                                    direction={'row'}
                                                    width={'100%'}
                                                    alignItems={'center'}
                                                    justifyContent={'space-between'}
                                                >
                                                    <Stack direction={'row'} width={'50px'} gap='8px' height="100%" alignItems='center' py='16px' style={{ marginLeft: '5px' }}>
                                                        <Typography style={{ lineHeight: '18px' }}>{index + 1}</Typography>
                                                        {index == 0 ?
                                                            <SvgIcon
                                                                component={First}
                                                                inheritViewBox
                                                                sx={{ fontSize: 16, marginRight: '16px' }}
                                                            /> : <></>}
                                                    </Stack>
                                                    <Stack direction={'row'} width={['160px', '240px']} height="100%" alignItems='center' py='16px' alignItems={'center'} marginLeft={'-15px'}>
                                                        <SvgIcon
                                                            component={ConetAvatar}
                                                            inheritViewBox
                                                            sx={{ fontSize: [16, 32], marginRight: ['4px', '16px'] }}
                                                        />
                                                        <Box>
                                                            <Typography fontSize={14} color={theme.ui.colors.text.secondary}>

                                                                {value.wallet.substring(0, 2) + value.wallet.substring(2, 8).toUpperCase() + '...' + value.wallet.substring(value.wallet.length - 6, value.wallet.length).toUpperCase()}
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                    <Stack width={'120px'} height="100%" alignItems='center' py='16px' alignItems={'center'} style={{ background: statusListTable == 'referrals' ? "#bebebe1c" : "none" }} >{value.referrals}</Stack>
                                                    <Stack width={'85px'} height="100%" alignItems='center' py='16px' textAlign={'right'} style={{ background: statusListTable == 'cntp' ? "#bebebe1c" : "none" }} >{Number(value.cntpRate).toFixed(6)}</Stack>
                                                </Stack>
                                            )
                                        })
                                        }
                                    </Stack>
                                )}
                            </>
                        ) :
                            (
                                <Stack direction={{ xl: 'row', md: 'column' }} style={{ display: 'flex', justifyContent: 'center', gap: '40px' }}>
                                    {leaderboardRenderer && leaderboardRenderer.free_cntp && leaderboardRenderer.free_referrals && (
                                        <>
                                            <Stack width={'100%'} px='24px' fontSize={14}>
                                                {leaderboardRenderer?.free_referrals.map((value, index) => {
                                                    return (
                                                        <Stack
                                                            key={index}
                                                            direction={'row'}
                                                            width={'100%'}
                                                            alignItems={'center'}
                                                            justifyContent={'space-between'}
                                                        >
                                                            <Stack direction={'row'} width={'50px'} gap='8px' height="100%" alignItems='center' py='16px' style={{ marginLeft: '5px' }}>
                                                                <Typography style={{ lineHeight: '18px' }}>{index + 1}</Typography>
                                                                {index == 0 ?
                                                                    <SvgIcon
                                                                        component={First}
                                                                        inheritViewBox
                                                                        sx={{ fontSize: 16, marginRight: '16px' }}
                                                                    /> : <></>}
                                                            </Stack>
                                                            <Stack direction={'row'} width={['160px', '240px']} height="100%" alignItems='center' py='16px' alignItems={'center'} marginLeft={'-15px'}>
                                                                <SvgIcon
                                                                    component={ConetAvatar}
                                                                    inheritViewBox
                                                                    sx={{ fontSize: [16, 32], marginRight: ['4px', '16px'] }}
                                                                />
                                                                <Box>
                                                                    <Typography fontSize={14} color={theme.ui.colors.text.secondary}>

                                                                        {value.wallet.substring(0, 2) + value.wallet.substring(2, 8).toUpperCase() + '...' + value.wallet.substring(value.wallet.length - 6, value.wallet.length).toUpperCase()}
                                                                    </Typography>
                                                                </Box>
                                                            </Stack>
                                                            <Stack width={'120px'} height="100%" alignItems='center' py='16px' alignItems={'center'} style={{ background: statusListTable == 'referrals' ? "#bebebe1c" : "none" }} >{value.referrals}</Stack>
                                                            <Stack width={'85px'} height="100%" alignItems='center' py='16px' textAlign={'right'} style={{ background: statusListTable == 'cntp' ? "#bebebe1c" : "none" }} >{Number(value.cntpRate).toFixed(6)}</Stack>
                                                        </Stack>
                                                    )
                                                })
                                                }
                                            </Stack>
                                        </>
                                    )}


                                </Stack>
                            )
                        }
                    </>
                </Stack>
            </Stack>
        </Stack >
    )
}

export default LaunchPage