import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Typography, SvgIcon, Stack, Box, Checkbox, Select, MenuItem, Skeleton, ListSubheader, CircularProgress, Link } from "@mui/material";
import { useTheme } from "styled-components";
import { loadCSS } from "fg-loadcss";
import {
  BannerIcon,
  CardNodeBannerFooter,
  PurchaseButtom,
  RedirectButtom, SuperNodeBannerBuyCardBuying,
  SuperNodeBannerContent,
  SuperNodeBannerFooter,
  SuperNodeBannerWrapper
} from "../styles/SuperNodeBanner";
import img1 from "../../../../../assets/images/img1.png";
import img2 from "../../../../../assets/images/img2.png";
import img3 from "../../../../../assets/images/img3.png";
import img4 from "../../../../../assets/images/img4.png";
import img5 from "../assets/sucess-transaction-icon.png";

import eth1 from '../assets/Ethereum ETH.svg';
import ethBlast from '../assets/Ethereum ETH-blast.svg';
import usdtBnb from '../assets/usdt-bnb.svg';
import stargateLogo from '../assets/stargate_logo.svg';
import bnbBnb from '../assets/bnb-bnb-logo.svg';
import usdt from '../assets/usdt.svg';


import cloudImage from "../../../../../assets/images/cloudImage.png";
import leftArrow from "../assets/left-arrow-node-banner.png";

import clock from '../assets/clock.svg';

import dentro from "../../../../../assets/images/dentro.svg";
import fora from "../../../../../assets/images/fora.svg";

import pay from "../../../../../assets/images/tether-usdt-seeklogo 1.png";
import eth from "../../../../../assets/images/ethereum-eth 1.png";
import coin3 from "../../../../../assets/images/coin3.png";
import coin4 from "../../../../../assets/images/coin4.png";

import failure from "../../../../../assets/images/failure.png";
import waitingTX from "../../../../../assets/images/waitingTX.png";

import { ReactComponent as Lock } from "../../../../../assets/icons/lock.svg";

import { ReactComponent as minusIcon } from "../../../../../assets/icons/minusIcon.svg";
import { ReactComponent as plusIcon } from "../../../../../assets/icons/plusIcon.svg";

import { ReactComponent as minusIconBlack } from "../../../../../assets/icons/minus_icon_black.svg";
import { ReactComponent as plusIconBlack } from "../../../../../assets/icons/plus_icon_black.svg";

import store, { useTypedSelector } from '../../../../../store/store';

import pointerRoullete from "../../../../../assets/images/pointerRoullete.png";


import { toast } from 'react-toastify';

import { setActiveProfile } from '../../../../../store/appState/appStateActions';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { listeningGuardianPurchaseHook, platform } from "../../../../../API/platform";
import { useFetchData } from "../../../../../API/refresh";
import ProfileTest from "../../profileTEST";

import conetLogo3d from '../assets/CONET LOGO 3D 5.png';
import guardianLogoOn from '../assets/guardian.svg';
import guardianLogoOnDark from '../assets/guardianDark.svg';
import guardianLogoOff from '../assets/guardianOff.svg';
import guardianEndImg from '../assets/guardianEnd.svg';
import shieldEndImg from '../assets/shieldEnd.svg';
import sentinelEndImg from '../assets/sentinelEnd.png';


import '../styles/superNodeBanner.css';
import { CopyToClipboard } from "../../../../../utilities/utilities";
import { MdOutlineContentCopy } from "react-icons/md";
import PlanCard from "../components/PlanCard";
import { motion } from "framer-motion";
import { useUserProfile } from "../../../../../API/useUserProfile";
import { useRefreshStore } from "../../../../../API/useRefreshStore";

export const SuperNodeBanner: React.FC = (props: any) => {
  const intl = useIntl();
  const { colors } = useTheme().ui;
  const [isBuying, setIsBuying] = useState<boolean>(false) //default => false
  const [guardianQuant, setGuardianQuant] = useState<number>(1)
  const [totalPrice, setTotalPrice] = useState<number | undefined>()//default ()
  const theme = useTheme();
  const [multiplierCNTP, setMultiplierCNTP] = useState<number>(1)
  const [isAgreementSigned, setIsAgreementSigned] = useState<boolean>(false)
  const [purchaseAble, setPurchaseAble] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<boolean>(false) //default => false
  const [wasAgreementRead, setWasAgreementRead] = useState<boolean>(false)
  const [isAgreementOpened, setIsAgreementOpen] = useState<boolean>(false)
  const [selectedCoin, setSelectedCoin] = useState<string>('none')
  const [displayCoin, setDisplayCoin] = useState<string>('usdt')
  const [coinImage, setCoinImage] = useState<string>('')
  const [conetPlatform, setConetPlatform] = useState<any>('')
  const [workerLoading, setWorkerLoading] = useState(0)
  const conet_platform = new platform(setConetPlatform, setWorkerLoading)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingFX, setIsLoadingFX] = useState<boolean>(false)
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [buyingLoading, setBuyingLoading] = useState<boolean>(false)
  const [isLoadingPrices, setIsLoadingPrices] = useState<boolean>(false)
  const [gasFee, setGasFee] = useState<string | null>('0')
  const [guardianPriceByCoin, setGuardianPriceByCoin] = useState<number>(1250)
  const [timer, setTimer] = useState(1200);
  const [asset, setAsset] = useState('')
  const [purchaseStatus, setPurchaseStatus] = useState<number>(0) //default 0
  const [purchaseMode, setPurchaseMode] = useState<string>('') //default ''
  const [txCode, setTxCode] = useState<string>('')
  const [isSelectedPlan, setIsSelectedPlan] = useState<string>('guardian')
  const [unlock, setUnlock] = useState(false)
  const [otherAssets, setOtherAssets] = useState()
  const [isGettingOtherAssets, setGettingOtherAssets] = useState<boolean>(false)

  const walletStore = useRefreshStore(state => state.wallets)
  const { userProfile, setUserProfile } = useUserProfile();

  const currentlyOwned = walletStore && walletStore.length ? walletStore?.filter(w => w.isNode === true).length : 0

  const profileIndex = useTypedSelector(state => state.appState.activeProfile)
  const [currentProfile, setCurrentProfile] = useState(profileIndex);
  const [profile, setProfile] = useState();

  const oracleDataStore = useRefreshStore(state => state.oracleAssets)
  useEffect(() => {
    if (selectedCoin === 'none') {
      setTotalPrice(1250)
      setDisplayCoin('usdt')
    } else {
      setTotalPrice(parseFloat(guardianQuant * guardianPriceByCoin))
    }
  }, [selectedCoin, guardianQuant])

  const plans = [
    {
      title: 'Guardian Plan',
      image: isSelectedPlan == 'guardian' ? localStorage.getItem('mui-mode') === 'light' ? guardianLogoOnDark : guardianLogoOn : guardianLogoOff,
      plan: 'guardian',
    },
    /*     {
          title: 'Sentinel Plan',
          image: isSelectedPlan == 'sentinel' ? localStorage.getItem('mui-mode') === 'light' ? sentinelLogoOnDark : sentinelLogoOn : sentinelLogoOff,
          plan: 'sentinel'
        },
        {
          title: 'Shield Plan',
          image: isSelectedPlan == 'shield' ? localStorage.getItem('mui-mode') === 'light' ? shieldLogoOnDark : shieldLogoOn : shieldLogoOff,
          plan: 'shield'
        } */
  ]

  function renderPlanLogo() {
    switch (isSelectedPlan) {
      case 'guardian':
        return guardianEndImg
        break
      case 'sentinel':
        return sentinelEndImg
        break
      default:
        return shieldEndImg
        break
    }
  }

  async function getAssets() {
    if (isGettingOtherAssets) return;


    setGettingOtherAssets(true)
    const res = await conet_platform.getAllOtherAssets()

    if (!res) {
      toast.error('Error on retrieving data, try again later', { position: "bottom-center", autoClose: 1500 })
    } else {

      setOtherAssets(res[0].tokens)
    }
    setGettingOtherAssets(false)
  }

  async function unlockGetAssets() {

    if (isGettingOtherAssets) return;
    await getAssets()
    setUnlock(true)
  }


  useEffect(() => {
    listeningGuardianPurchaseHook(setPurchaseStatus)
    async function getAllAssets() {
      await conet_platform.getAllOtherAssets()
    }
    getAllAssets()
  })

  // useEffect(() => {
  //   // getAssets()

  //   console.log(profile)
  // }, [])
  useEffect(() => {
    const updateProfile = () => {
      const keyID = profileIndex?.keyID?.toLowerCase() || "0"
      const profiles = walletStore && walletStore.length > 0 ? walletStore : []
      if (!profiles) return false
      const index = profiles.findIndex(n => n.keyID.toLowerCase() === keyID)
      if (index < 0) {
        return
      }
      setProfile(profiles[index])
      setCurrentProfile(profiles[index])
    }
    updateProfile()
  }, [walletStore]);

  /*   useEffect(() => {
  
      if (selectedCoin == 'wusdt') {
        setDisplayCoin('usdt')
        return
      }
  
      if (selectedCoin == 'BlastETH') {
        setDisplayCoin('eth')
        return
      }
  
      if (selectedCoin == 'none') {
        setDisplayCoin('usdt')
        return
      }
  
      setDisplayCoin(selectedCoin)
  
    }, [selectedCoin]) */


  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
  });

  // Devider layout configuration
  const borderDevider = localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#111113"
  const circleDevider = localStorage.getItem('mui-mode') === 'light' ? "#f6f8ff" : "#303032"
  const colorDevider = localStorage.getItem('mui-mode') === 'light' ? "#49454f" : "#111113"

  const pointerProperties = {
    src: pointerRoullete,
    style: {
      top: 'calc(50% - 16px)',
      width: '37px',
      height: '31px',
      right: '-10px'
    }
  }

  // const wheelData = [
  //   { option: 'WIN', style: { backgroundColor: "#79F8FF", textColor: 'black' }, optionSize: 1 },
  //   { option: 'LOSE', style: { backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#577DFF" : "#303032", textColor: 'white' }, optionSize: 4 },
  //   { option: 'WIN', style: { backgroundColor: "#79F8FF", textColor: 'black' }, optionSize: 1 },
  //   { option: 'LOSE', style: { backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#577DFF" : "#303032", textColor: 'white' }, optionSize: 4 },
  //   { option: 'WIN', style: { backgroundColor: "#79F8FF", textColor: 'black' }, optionSize: 1 },
  //   { option: 'LOSE', style: { backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#577DFF" : "#303032", textColor: 'white' }, optionSize: 4 },
  // ]

  // useEffect(() => {
  //   const interval =  setInterval( async () => {
  //     setTimer(prevTimer => prevTimer - 1); // Decrease timer by 1 every second
  //     if (timer === 0) {
  //       if(purchaseStatus > 0 && purchaseStatus < 4){
  //         await updateGuardianPrice()
  //         await confirmPrePurchase();
  //       }
  //       setTimer(60); // Reset timer to 60 seconds
  //     }
  //   }, 1000); // 1 second interval

  //   return () => clearInterval(interval);
  // }, [timer]);

  const authKey = useTypedSelector((state) => state.appState.pass);

  const updateProfiles = async () => {

    const res_getall = walletStore && walletStore.length ? walletStore : []
    for (let i = 0; i < res_getall[0].length; i++) {
      if (res_getall[0][i]?.keyID && userProfile?.keyID == res_getall[0][i]?.keyID) {
        await store.dispatch(setActiveProfile(res_getall[0][i]));
        setUserProfile(res_getall[0[i]])
      }
    }
  }

  useEffect(() => {
    if (purchaseStatus == 4) {
      setTimeout(() => {
        updateProfiles()
      }, 2000);
    }
  }, [purchaseStatus]);

  const formatTime = (time: any) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * wheelData.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  }
  const selectAsset = (asset: string) => {
    switch (asset) {
      case 'ARB':
        setAsset('aeth')
        break
      case 'bsc':
        setAsset('bnb')
        break
      case 'eth':
        setAsset('eth')
        break
      default:
        setAsset('conet')
        break
    }
  }

  const handleChange = async (event: any) => {
    changeCoinImage(event.target.value)
    selectAsset(event.target.value.split('-')[0])
    setSelectedCoin(event.target.value.split('-')[1]);
  };

  const currentUrl = window.location.href;
  useEffect(() => {
    function verifyPurchase(url: string) {
      const regex = /purchase=true/;
      const isPurchaseTrue = regex.test(url);
      setPurchaseAble(isPurchaseTrue)
    }
    verifyPurchase(currentUrl)
  }, [currentUrl])


  const confirmBuy = async () => {
    await updateGuardianPrice()
    setTotalPrice(guardianQuant * guardianPriceByCoin)
    return guardianQuant * guardianPriceByCoin
  }

  const confirmFXPrePurchase = async () => {
    const result = await conet_platform.fx168PrePurchase(guardianQuant)
    setTxCode(result[0]?.id.toString())
    setIsBuying(!isBuying)
    setPurchaseMode('fx168')
    setIsLoadingFX(false)
  }
  const confirmPrePurchase = async () => {

    await confirmBuy()

    if (true) { //userProfile?.tokens[selectedCoin].balance >= guardianQuant * guardianPriceByCoin
      const res = await conet_platform.prePurchase(guardianQuant, String(guardianQuant * guardianPriceByCoin), userProfile, selectedCoin)
      setGasFee(res[1])
      setIsBuying(!isBuying)
    } else {
      toast.error('Not enought balance!', { position: "bottom-center", autoClose: 1500 })
    }
    setIsLoading(false)
  }

  const buyGuardian = async () => {
    await conet_platform.guardianPurchase(guardianQuant, String(guardianQuant * guardianPriceByCoin), userProfile, selectedCoin)
    setTimeout(() => {
      const { wallet, referees, leaderboard } = useFetchData();
    }, 1000)
  }

  const fxBuy = async () => {
    const res = await conet_platform.fx168PrePurchase(guardianQuant)
  }
  const updateGuardianPrice = async () => {
    setIsLoadingPrices(true)
    let coin = selectedCoin
    //const res = await conet_platform.getAssetsPrice()
    switch (selectedCoin) {
      case 'arb_usdt':
      case 'wusdt':
      case 'usdt':
        coin = 'usdt'
        break
      case 'arb_eth':
      case 'eth':
        coin = 'eth'
        break
      case 'wbnb':
        coin = 'bnb'
        break
    }
    /*       let searchAsset = selectedCoin.toLowerCase()
          if (searchAsset === 'wbnb') searchAsset = 'bnb'
          if (searchAsset === 'weth') searchAsset = 'eth'
          if (searchAsset === 'usdt') searchAsset = 'usdt' */
    let currency_data;
    let coin_price;
    currency_data = oracleDataStore && oracleDataStore.assets ? oracleDataStore.assets.find(item => item.name === coin) : ''
    coin_price = currency_data && oracleDataStore.assets ? currency_data.price : 1
    if (selectedCoin !== 'none') {
      setDisplayCoin(coin)
    }
    if (coin === 'usdt') {
      setGuardianPriceByCoin(1250)
    } else {
      coin_price ? await setGuardianPriceByCoin((1250 / parseFloat(coin_price)).toFixed(4)) : 1
    }

    setIsLoadingPrices(false)
  }

  useEffect(() => {
    updateGuardianPrice()
  }, [selectedCoin]);

  useEffect(() => {
    const fetchData = async () => {
      if (!active) return;

      const node = loadCSS(
        "https://use.fontawesome.com/releases/v6.5.1/css/all.css",
        //@ts-ignore
        document.querySelector("#font-awesome-css") || document.head.firstChild
      );

      return () => {
        active = false;
        node.parentNode!.removeChild(node);
      };
    };

    let active = true;
    fetchData();
  }, []);


  const getRefferRate = (num: number) => {

    if (num >= 100) {
      return 3
    }

    if (num < 2) {
      return 1
    }

    return (100 + 2 * num) / 100

  }

  useEffect(() => {
    setMultiplierCNTP(getRefferRate(guardianQuant + currentlyOwned))
  }, [currentlyOwned])

  function changeCoinImage(value: any) {
    switch (value) {
      case 'ARB-arb_usdt':
        setCoinImage(usdtBnb)
        break
      case 'ARB-arb_eth':
        setCoinImage(eth)
        break
      case 'BSC-wusdt':
        setCoinImage(usdtBnb)
        break
      case 'BSC-wbnb':
        setCoinImage(bnbBnb)
        break
      case 'ETH-eth':
        setCoinImage(eth)
        break
      case 'ETH-usdt':
        setCoinImage(pay)
        break
    }
  }

  useEffect(() => {
    const element = document.getElementById(`airdrop`);
    if (!element) return;

    element.innerHTML = element.innerHTML.replace(",", `<br />`);
    element.innerHTML = element.innerHTML.replace(";", `<br />`);
  }, []);

  // console.log(isBuying, totalPrice, confirm, buyingLoading)
  return (
    <SuperNodeBannerWrapper margin="auto" width={'100%'}>
      <Box display='flex' flexDirection={['column', 'column']} p={'16px'} gap='16px'>
        <Box style={{ display: 'flex', position: 'relative', justifyContent: 'center' }}>
          {/*<span style={{ borderRadius: '50%', background: circleDevider, border: '5px solid black', borderColor: borderDevider, textAlign: "center", height: '50px', width: '50px', zIndex: '2', lineHeight: '40px', color: colorDevider }}>1</span>
          <span style={{ height: '25px', borderBottom: '1px solid #929092', maxWidth: '250px', width: '100%', position: 'absolute' }}></span>*/}
        </Box>
        <Box display='flex' justifyContent='space-between' flexDirection={['column', 'column', 'row']} gap={[6, 12]} p={['16px', 0, 0]} alignItems='flex-start'>
          <SuperNodeBannerContent>
            <Box display='flex' width="100%" flexDirection={['column', 'column', 'row']} gap={[6, 12]} alignItems='center'>
              <Stack direction="column" flex="1" spacing={4} maxWidth={'430px'}>
                <Stack spacing={1}>

                  <Typography sx={{ textAlign: `left`, width: '100%', maxWidth: `281px`, lineHeight: `3rem` }} fontSize={['24px', '24px', '36px']}>
                    Embracing <span style={{ color: ' #8DA8FF', fontWeight: 700 }}> Decentralization </span> for Everyone
                  </Typography>
                </Stack>

                <Stack direction="column" gap="0.5rem">

                  <Stack direction="row" alignItems="center" gap="0.5rem">
                    <img width={'25px'} src={pay} />
                    <img width={'25px'} src={eth} />
                    <img width={'25px'} src={bnbBnb} />
                    <img width={'25px'} src={usdtBnb} />
                  </Stack>

                  <Typography fontSize="14px" fontWeight="600">
                    Pay in USDT or ETH or BNB or AETH
                  </Typography>
                </Stack>

                <Stack>
                  <Typography fontSize="14px" fontWeight="600">
                    Bring your assets from other networks
                  </Typography>

                  <motion.div whileHover={{ scale: 1.0125 }} whileTap={{ scale: 1.0125 }} style={{ width: "100%", padding: '1px', }} className="cardAbove">
                    <Link href="https://stargate.finance/bridge" target="_blank">
                      <Box
                        sx={{
                          borderRadius: '8px',
                          background: "#363E59",
                          padding: "8px 0px",
                          width: "100%",
                          textAlign: "center",
                          marginTop: '8px',
                          color: "white",
                          fontWeight: 800,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "4px"
                        }}>
                        <img src={stargateLogo} height={25} width={25} />
                        <Typography fontSize="14px" fontWeight="600">
                          Stargate Bridge
                        </Typography>
                      </Box>
                    </Link>
                  </motion.div>
                </Stack>


                <Stack>
                  <Typography fontSize="14px" fontWeight="600">
                    Your Available Balance
                  </Typography>

                  {
                    unlock ? (
                      <Box style={{ marginTop: '8px', border: localStorage.getItem("mui-mode") === 'light' ? "1px solid #E4E2E4" : '1px solid #474648', padding: '24px', borderRadius: '16px' }} maxWidth={'360px'}>

                        <Typography fontSize="16px" fontWeight="700" style={{ marginBottom: '8px' }}>Binance Smart Chain</Typography>

                        <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                          <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                            <img src={usdtBnb} height={25} width={25} />
                            <Typography fontSize="14px" fontWeight="600">USDT</Typography>
                          </Box>
                          <Typography fontSize="14px" fontWeight="600">$ {otherAssets ? parseFloat(otherAssets?.wusdt?.balance).toFixed(4) : 0}</Typography>
                        </Stack>

                        <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                          <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                            <img width={'25px'} src={bnbBnb} />
                            <Typography fontSize="14px" fontWeight="600">BNB</Typography>
                          </Box>
                          <Typography fontSize="14px" fontWeight="600"> $ {otherAssets ? parseFloat(otherAssets?.bnb?.balance).toFixed(4) : 0}</Typography>
                        </Stack>


                        <Typography fontSize="16px" fontWeight="700" style={{ marginBottom: '8px' }}>ETH Network</Typography>
                        <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                          <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                            <img width={'25px'} src={eth1} />
                            <Typography fontSize="14px" fontWeight="600">ETH</Typography>
                          </Box>
                          <Typography fontSize="14px" fontWeight="600">$ {otherAssets && !isNaN(parseFloat(otherAssets?.eth?.balance)) ? parseFloat(otherAssets?.eth?.balance).toFixed(4) : 0}</Typography>
                        </Stack>

                        <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                          <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                            <img width={'25px'} src={usdt} />
                            <Typography fontSize="14px" fontWeight="600">USDT</Typography>
                          </Box>
                          <Typography fontSize="14px" fontWeight="600">$ {otherAssets && !isNaN(parseFloat(otherAssets?.usdt?.balance)) ? parseFloat(otherAssets?.usdt?.balance).toFixed(4) : 0}</Typography>
                        </Stack>

                        <Typography fontSize="16px" fontWeight="700" style={{ marginBottom: '8px' }}>Arbitrum Network</Typography>
                        <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                          <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                            <img width={'25px'} src={usdt} />
                            <Typography fontSize="14px" fontWeight="600">USDT</Typography>
                          </Box>
                          <Typography fontSize="14px" fontWeight="600">
                            $ {otherAssets && !isNaN(parseFloat(otherAssets?.arb_usdt?.balance))
                              ? parseFloat(otherAssets?.arb_usdt?.balance).toFixed(4)
                              : 0}
                          </Typography>
                        </Stack>

                        <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                          <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                            <img width={'25px'} src={eth1} />
                            <Typography fontSize="14px" fontWeight="600">ETH</Typography>
                          </Box>
                          <Typography fontSize="14px" fontWeight="600">
                            $ {otherAssets && !isNaN(parseFloat(otherAssets?.arb_eth?.balance))
                              ? parseFloat(otherAssets?.arb_eth?.balance).toFixed(4)
                              : 0}
                          </Typography>
                        </Stack>


                        {/*                         <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} ml={'8px'} mb={'16px'}>
                          <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                            <img width={'25px'} src={ethBlast} />
                            <Typography fontSize="14px" fontWeight="600">ETH</Typography>
                          </Box>
                          <Typography fontSize="14px" fontWeight="600">$ {otherAssets ? otherAssets?.blastETH?.balance : 0}</Typography>
                        </Stack> */}

                        {/* <Typography fontSize="16px" fontWeight="700" style={{ marginBottom: '8px' }}>CONET Holesky</Typography> */}

                        {/* <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                        <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                          <img width={'25px'} src={eth1} />
                          <Typography fontSize="14px" fontWeight="600">dWETH</Typography>
                        </Box>
                        <Typography fontSize="14px" fontWeight="600">$ {profile?.tokens?.dWETH.balance}</Typography>
                      </Stack>


                      <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} ml={'8px'} mb={'16px'}>
                        <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                          <img width={'25px'} src={bnbBnb} />
                          <Typography fontSize="14px" fontWeight="600">dWBNB</Typography>
                        </Box>
                        <Typography fontSize="14px" fontWeight="600">$ {profile?.tokens?.dWBNB.balance}</Typography>
                      </Stack> */}

                        {/* <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} ml={'8px'}>
                        <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                          <img width={'25px'} src={usdt} />
                          <Typography fontSize="14px" fontWeight="600">dUSDT</Typography>
                        </Box>
                        <Typography fontSize="14px" fontWeight="600">$ {profile?.tokens?.dUSDT.balance}</Typography>
                      </Stack> */}

                        <PurchaseButtom
                          style={{
                            width: '100%',
                            textAlign: "center",
                            maxWidth: '300px',
                            margin: '0 auto',
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                            fontSize: "15px",
                            padding: "0.65rem",
                            borderRadius: "0.75rem",
                            cursor: "pointer",
                            backgroundColor: circleDevider
                          }}
                          onClick={() => getAssets()
                          }
                        >

                          {!isGettingOtherAssets ? "Refresh Balance" : <CircularProgress size={24} color="inherit" />}
                        </PurchaseButtom>

                      </Box>
                    ) :
                      (

                        <Box style={{ marginTop: '8px', border: localStorage.getItem("mui-mode") === 'light' ? "1px solid #E4E2E4" : '1px solid #474648', padding: '24px', borderRadius: '8px' }} maxWidth={'360px'} position={'relative'}>

                          <Box style={{ opacity: 0.2 }}>
                            <Typography fontSize="16px" fontWeight="700" style={{ marginBottom: '8px' }}>Binance Smart Chain</Typography>

                            <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                              <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                                <img src={usdtBnb} height={25} width={25} />
                                <Typography fontSize="14px" fontWeight="600">USDT</Typography>
                              </Box>
                              <Typography fontSize="14px" fontWeight="600">$0</Typography>
                            </Stack>

                            <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                              <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                                <img width={'25px'} src={bnbBnb} />
                                <Typography fontSize="14px" fontWeight="600">BNB</Typography>
                              </Box>
                              <Typography fontSize="14px" fontWeight="600"> $0</Typography>
                            </Stack>


                            <Typography fontSize="16px" fontWeight="700" style={{ marginBottom: '8px' }}>ETH Network</Typography>
                            <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                              <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                                <img width={'25px'} src={eth1} />
                                <Typography fontSize="14px" fontWeight="600">ETH</Typography>
                              </Box>
                              <Typography fontSize="14px" fontWeight="600">$0</Typography>
                            </Stack>

                            <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                              <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                                <img width={'25px'} src={usdt} />
                                <Typography fontSize="14px" fontWeight="600">USDT</Typography>
                              </Box>
                              <Typography fontSize="14px" fontWeight="600">$0</Typography>
                            </Stack>

                            <Typography fontSize="16px" fontWeight="700" style={{ marginBottom: '8px' }}>Arbitrum Network</Typography>
                            <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                              <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                                <img width={'25px'} src={usdt} />
                                <Typography fontSize="14px" fontWeight="600">USDT</Typography>
                              </Box>
                              <Typography fontSize="14px" fontWeight="600">$0</Typography>
                            </Stack>

                            <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} mb={'16px'} ml={'8px'}>
                              <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                                <img width={'25px'} src={eth1} />
                                <Typography fontSize="14px" fontWeight="600">ETH</Typography>
                              </Box>
                              <Typography fontSize="14px" fontWeight="600">$0</Typography>
                            </Stack>


                            {/*                             <Stack direction="row" alignItems="center" gap="0.5rem" justifyContent={'space-between'} ml={'8px'} mb={'16px'}>
                              <Box display={'flex'} alignItems={'center'} gap="0.5rem">
                                <img width={'25px'} src={ethBlast} />
                                <Typography fontSize="14px" fontWeight="600">ETH</Typography>
                              </Box>
                              <Typography fontSize="14px" fontWeight="600">$0</Typography>
                            </Stack> */}

                          </Box>

                          <Box style={{
                            position: 'absolute', top: 0, left: 0, width: "100%", height: '100%', background: '#a8a8a838', display: 'flex', alignItems: 'center', justifyContent: 'center',
                          }}>

                            <motion.div
                              whileTap={{ scale: 0.97 }}
                              onClick={async () => { unlockGetAssets() }}
                            >
                              <PurchaseButtom
                                style={{
                                  width: '200px',
                                  textAlign: "center",
                                  maxWidth: '300px',
                                  margin: '0 auto',
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                  padding: "0.65rem",
                                  borderRadius: "0.75rem",
                                  cursor: "pointer",
                                  backgroundColor: circleDevider
                                }}
                                disabled={false}
                              >

                                {!isGettingOtherAssets ? "Get Assets Balance" : <CircularProgress size={24} color="inherit" />}
                              </PurchaseButtom>
                            </motion.div>
                          </Box>
                        </Box>


                      )
                  }

                </Stack>

              </Stack>
              {/*<SuperNodeBannerBuyCardBuying
              style={{
                backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#1B1B1D",
                border: localStorage.getItem("mui-mode") === 'light' ? "1px solid #E4E2E4" : '1px solid #474648',
                width: '100%',
                maxWidth: '550px'
              }}>
              {(!isBuying && !totalPrice) &&
                (
                  <>
                    <Stack direction="column" spacing={4} color={localStorage.getItem('mui-mode') === 'light' ? "black" : "white"}>
                      <Box>
                        <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>
                          Select Account
                        </Typography>
                        <Box position="relative" display="flex" flexDirection="column" pb="24px">
                          <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#262527"}>
                            <Box display="flex" gap="8px" alignItems="center">
                              <Box display="flex" flexDirection="column">
                                <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'700'}>
                                  Account 04
                                </Typography>
                                <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                  0x412BA4...03AB46
                                </Typography>
                              </Box>
                            </Box>
                            <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                              <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box style={{ marginTop: '0' }}>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                          <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>
                            Select Quantity
                          </Typography>
                        </Box>
                        <Box position="relative" display="flex" flexDirection="column" pb="24px">
                          <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#262527"}>
                            <SvgIcon component={localStorage.getItem('mui-mode') === 'light' ? minusIconBlack : minusIcon} inheritViewBox fontSize="large" onClick={() => { setMultiplierCNTP(getRefferRate(guardianQuant > 1 ? guardianQuant - 1 : 1)); guardianQuant > 1 ? setGuardianQuant(guardianQuant - 1) : null }} />
                            <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '24px' }}>
                              {guardianQuant}
                            </Typography>
                            <SvgIcon component={localStorage.getItem('mui-mode') === 'light' ? plusIconBlack : plusIcon} inheritViewBox fontSize="large" onClick={() => { setMultiplierCNTP(getRefferRate(guardianQuant < 99 ? guardianQuant + 1 : 99)); setGuardianQuant(guardianQuant < 99 ? guardianQuant + 1 : 99); }} />
                          </Box>
                        </Box>
                      </Box>
                    </Stack>

                    <Stack flex="1" direction="column" justifyContent="flex-end">
                      <PurchaseButtom
                        onClick={() => setIsBuying(true)}
                        style={{
                          width: '100%',
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontSize: "15px",
                          padding: "0.65rem",
                          borderRadius: "0.75rem",
                          cursor: "pointer",
                          background: '#585a5f',
                          color: '#979797',
                          height: '56px',
                          backgroundColor: circleDevider
                        }}
                        disabled={true}
                      >
                        Purchase Soon
                      </PurchaseButtom>

                      <Stack flexDirection={'row'} justifyContent={'center'} marginTop={'12px'} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                        <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                        Secure payment
                      </Stack>
                    </Stack>
                  </>
                )}
              {(isBuying && totalPrice && !confirm) && (
                <Box>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '0px' }}>
                    <img
                      src={leftArrow}
                      alt='back'
                      height={30}
                      width={30}
                      style={{ cursor: 'pointer' }}
                      onClick={() => { setIsBuying(false); setTotalPrice(undefined) }}
                    />
                    <Typography
                      fontSize="24px"
                      color="#8DA8FF"
                      fontWeight={'700'}
                    >
                      Confirm your order
                    </Typography>
                  </div>

                  <div>
                    <Typography style={{ marginBottom: '8px', marginTop: '40px', fontWeight: 700, fontSize: '16px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Paying with</Typography>
                    <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="16px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>

                      <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '20px', color: '#fff' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {selectedCoin === 'usdt' && <img src={pay} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />}
                          {selectedCoin === 'bnb' && <img src={coin4} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px', height: '25px', width: '25px' }} />}
                          {selectedCoin === 'eth' && <img src={eth} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />}
                          <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>
                            {selectedCoin.toUpperCase()}
                          </Typography>
                        </div>
                      </Typography>

                    </Box>
                    <Typography style={{ marginBottom: '8px', marginTop: '40px', fontWeight: 700, fontSize: '16px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Wallet</Typography>
                    {
                      data.wallet.length && (
                        <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>

                          <Box display="flex" gap="8px" alignItems="center">


                            {data.wallet[0]?.data && data.wallet[0]?.data.profileImg ? (
                              <img
                                src={` ${data.wallet[0]?.data.profileImg}`}
                                alt="Imagem Codificada em Base64"
                                height="20px"
                                width="20px"
                                style={{ borderRadius: "100%" }}
                              />
                            ) : (
                              <SvgIcon
                                component={ConetAvatar}
                                inheritViewBox
                                sx={{ fontSize: 32 }}
                              />
                            )}

                            <Box display="flex" flexDirection="column">
                              <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>
                                {currentProfile?.data?.nickname || intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' })}
                              </Typography>
                              <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                {currentProfile?.keyID.substring(0, 2) + currentProfile?.keyID.substring(2, 8).toUpperCase() + '...' + currentProfile?.keyID.substring(currentProfile?.keyID.length - 6, currentProfile?.keyID.length).toUpperCase()}
                              </Typography>
                            </Box>
                          </Box>

                        </Box>)
                    }
                    <Typography style={{ marginBottom: '8px', marginTop: '40px', fontWeight: 700, fontSize: '16px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Summary</Typography>
                    <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>

                      <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '20px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography style={{ fontSize: '20px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>{multiplierCNTP * guardianQuant * 1000}  </Typography>
                          {selectedCoin.toUpperCase()}
                        </div>
                      </Typography>

                    </Box>
                  </div>

                  <Typography style={{ color: '#fff', marginTop: '24px' }}>GAS fee: 0%</Typography>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '164px' }}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                      <Checkbox checked={isAgreementSigned} onChange={() => setIsAgreementSigned(!isAgreementSigned)}></Checkbox>
                      <Typography style={{ fontSize: '16px', marginTop: '5px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>I agree the <span style={{ textDecoration: 'underline' }}>agreements</span></Typography>
                    </Box>
                    <PurchaseButtom
                      style={{
                        width: '100%',
                        textAlign: "center",
                        maxWidth: '300px',
                        margin: '0 auto',
                        marginTop: '32px',
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "600",
                        fontSize: "15px",
                        padding: "0.65rem",
                        borderRadius: "0.75rem",
                        cursor: "pointer",
                      }}
                      disabled={!isAgreementSigned}
                      onClick={() => setConfirm(true)}
                    >
                      Pay {selectedCoin.toUpperCase()} ${multiplierCNTP * guardianQuant * 1000}
                    </PurchaseButtom>
                    <Stack flexDirection={'row'} justifyContent={'center'} marginTop={'12px'} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                      <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                      Secure payment
                    </Stack>
                  </div>

                </Box>
              )}
              {(isBuying && totalPrice && confirm) && (
                <Box>
                  <Box>
                    <img src={img5} alt='success' width='32px' />
                    <Typography style={{ fontSize: '36px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>The transaction </Typography>
                    <p>
                      <span style={{ color: ' #8DA8FF', fontWeight: 700, fontSize: '36px' }}> was successful </span>
                    </p>
                  </Box>
                  <div>
                    <div style={{ display: 'flex', marginTop: '20px', fontSize: '24px' }}>
                      <Typography fontSize={'24px'} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Fiat value </Typography>
                      <Typography fontSize={'24px'} style={{ marginLeft: '8px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}> {totalPrice} {selectedCoin.toUpperCase()}</Typography>
                    </div>
                    <Typography style={{ marginTop: '24px', marginBottom: '200px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}> Gas Fee: 0%</Typography>
                    <Box bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"} style={{ borderRadius: '16px', padding: '8px 16px', display: 'flex', gap: '24px' }}>
                      <img src={img6} width={72} />
                      <Typography fontSize="24px" fontWeight="400" lineHeight='32px' color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}><span style={{ fontWeight: 'bold' }}>Congratulations! </span>You now have <span style={{ color: ' #8DA8FF', fontWeight: 700 }}>100</span> guardian nodes.</Typography>
                    </Box>
                    <div style={{ marginTop: '32px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography style={{ color: ' #8DA8FF', fontWeight: 700, cursor: 'pointer' }} onClick={() => <ProfileTest />}>
                        Go to profile
                      </Typography>
                      <PurchaseButtom
                        style={{
                          width: '100%',
                          textAlign: "center",
                          maxWidth: '300px',
                          margin: '8px auto',
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontSize: "15px",
                          padding: "0.65rem",
                          borderRadius: "0.75rem",
                          cursor: "pointer",
                        }}
                        onClick={() => { setIsBuying(false); setTotalPrice(undefined); setConfirm(false) }}
                      >
                        Make new one
                      </PurchaseButtom>
                      <Stack flexDirection={'row'} justifyContent={'center'} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                        <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                        Secure payment
                      </Stack>
                    </div>

                  </div>

                </Box>
              )}
            </SuperNodeBannerBuyCardBuying>*/}
            </Box>
          </SuperNodeBannerContent >

          {/* GUARDIAN BANNER WEBSITE */}
          {/* <Box style={{display:'flex', justifyContent:'center', marginBottom:'32px'}}>
          <Become/>
        </Box> */}

          {/* ROULLETE WHEEL */}
          {/*<Box style={{ display: 'flex', position: 'relative', justifyContent: 'center' }}>
          <span style={{ borderRadius: '50%', background: circleDevider, border: '5px solid black', borderColor: borderDevider, textAlign: "center", height: '50px', width: '50px', zIndex: '2', lineHeight: '40px', color: colorDevider }}>2</span>
          <span style={{ height: '25px', borderBottom: '1px solid #929092', maxWidth: '250px', width: '100%', position: 'absolute' }}></span>
        </Box> */}
          {/*         <Box>
          <Typography fontSize="36px" lineHeight='32px' fontWeight='800' textAlign={'center'} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Spin the Wheel</Typography>
        </Box>*/}

          {/*         <Box style={{ display: 'flex', marginBottom: '32px', alignItems: 'center', justifyContent: 'space-around' }}>
          <Box style={{ display: 'flex' }} flexDirection={'column'} maxWidth={'320px'} alignItems={'center'}>
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={wheelData}
              spinDuration={0.5}
              outerBorderColor={localStorage.getItem('mui-mode') === 'light' ? "#D6E3FF" : "#000"}
              outerBorderWidth={20}
              radiusLineWidth={0}
              fontSize={12}
              onStopSpinning={() => {
                setMustSpin(false);
              }}

              // pointerRoullete
              pointerProps={pointerProperties}
            />
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
              <img width={'14px'} height={'14px'} src={certificate_icon} />
              <Typography style={{ fontSize: "14px" }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}> x 0 Subscription Certificates</Typography>
            </Box>

            <PurchaseButtom
              style={{
                width: '100%',
                height: '46px',
                textAlign: "center",
                maxWidth: '130px',
                margin: '0 auto',
                marginTop: '32px',
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "600",
                fontSize: "15px",
                padding: "0.65rem",
                borderRadius: "0.75rem",
                cursor: "pointer",
                backgroundColor: circleDevider
              }}
              disabled={true}
            //onClick={() => { console.log("click"); handleSpinClick() }}
            >
              SPIN
            </PurchaseButtom>
          </Box>
                  <Box style={{ border: '1px solid #474648', borderRadius: '8px', height: '350px', padding: '24px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Stack>
            <Typography style={{ fontSize: "36px", fontWeight: '800' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>You Won!</Typography>
            <Typography style={{ fontSize: "14px" }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>You are now elegible to buy Guardian Plan.</Typography>
          </Stack>
          <Stack>
            <Typography style={{ fontSize: "14px", fontWeight: '800', marginBottom: '8px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>History</Typography>
            <Typography style={{ fontSize: "14px" }} color={localStorage.getItem('mui-mode') === 'light' ? "#929092" : "#929092"}>1x you lost</Typography>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography style={{ fontSize: "14px" }} color={localStorage.getItem('mui-mode') === 'light' ? "#79F8FF" : "#79F8FF"}>1x you won</Typography>
              <Typography style={{ fontSize: "14px" }} color={localStorage.getItem('mui-mode') === 'light' ? "#929092" : "#929092"}>98498486498764989</Typography>
            </Box>
          </Stack>
        </Box>
        </Box>*/}

          {/*         <Box style={{ display: 'flex', position: 'relative', justifyContent: 'center' }}>
          <span style={{ borderRadius: '50%', background: circleDevider, border: '5px solid black', borderColor: borderDevider, textAlign: "center", height: '50px', width: '50px', zIndex: '2', lineHeight: '40px', color: colorDevider }}>3</span>
          <span style={{ height: '25px', borderBottom: '1px solid #929092', maxWidth: '250px', width: '100%', position: 'absolute' }}></span>
        </Box>*/}
          <SuperNodeBannerContent style={{
            width: '100%',
            maxWidth: "550px"
          }}>
            <Box display='flex' width="100%" flexDirection={['column', 'column', 'row']} gap={[6, 12]} alignItems='flex-start'>
              <SuperNodeBannerBuyCardBuying
                style={{
                  backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#FFFFFF" : "#1B1B1D",
                  border: localStorage.getItem("mui-mode") === 'light' ? "1px solid #E4E2E4" : '1px solid #474648',
                  width: '100%',
                  maxWidth: '550px'
                }}>
                {(!isBuying) &&
                  (
                    <>
                      <Stack direction="column" spacing={4} color={localStorage.getItem('mui-mode') === 'light' ? "black" : "white"}>
                        <Stack>
                          <Typography
                            fontSize="24px"
                            color="#8DA8FF"
                            fontWeight={'700'}
                          >
                            Become a Super Node
                          </Typography>
                        </Stack>

                        <Typography marginBottom={2} fontSize={16} fontWeight={700}>Choose a plan</Typography>
                        <Box display={'flex'} flexDirection={'row'} gap={[1, 4]} width={'100%'}>
                          {plans.map((p) => <PlanCard plan={p.plan} title={p.title} image={p.image} isSelectedPlan={isSelectedPlan} setIsSelectedPlan={setIsSelectedPlan} />)}
                        </Box>

                        <Box>
                          <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>
                            Current Account
                          </Typography>
                          <Box position="relative" display="flex" flexDirection="column" pb="24px">
                            <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>
                              <Box display="flex" gap="8px" alignItems="center">
                                <Box display="flex" flexDirection="column">
                                  <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>
                                    {currentProfile?.data?.nickname || intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' })}
                                  </Typography>
                                  <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                                    {currentProfile?.keyID?.substring(0, 2) + currentProfile?.keyID?.substring(2, 8).toUpperCase() + '...' + currentProfile?.keyID?.substring(currentProfile?.keyID?.length - 6, currentProfile?.keyID?.length).toUpperCase()}
                                  </Typography>
                                </Box>
                              </Box>
                              {/* <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                                <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                              </Box> */}
                            </Box>
                          </Box>
                        </Box>

                        <Box>
                          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                            <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>
                              Select Quantity
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>
                              {/* Receive @multiplier x more CNTP */}
                              Current owned: {currentlyOwned}
                            </Typography>
                          </Box>

                          <Box position="relative" display="flex" flexDirection="column" pb="24px">
                            <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>
                              <SvgIcon component={localStorage.getItem('mui-mode') === 'light' ? minusIconBlack : minusIcon} inheritViewBox fontSize="large" onClick={() => { setMultiplierCNTP(getRefferRate(guardianQuant > 1 ? guardianQuant - 1 + currentlyOwned : 1 + currentlyOwned)); guardianQuant > 1 ? setGuardianQuant(guardianQuant - 1) : null }} />
                              <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '24px' }}>
                                {guardianQuant}
                              </Typography>
                              <SvgIcon component={localStorage.getItem('mui-mode') === 'light' ? plusIconBlack : plusIcon} inheritViewBox fontSize="large" onClick={() => { setMultiplierCNTP(getRefferRate(guardianQuant + 1 + currentlyOwned)); setGuardianQuant(guardianQuant + 1); }} />
                            </Box>
                          </Box>
                        </Box>

                        <Box style={{ margin: 0, marginTop: '-8px' }}>
                          <Box style={{ background: 'linear-gradient(92.05deg, rgb(215, 117, 255) 0%, rgb(141, 168, 255) 100%)', borderRadius: '8px', width: '100%', padding: '1px', maxWidth: '280px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"} style={{ width: 'calc(100% - 1px)', borderRadius: '7px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <Typography sx={{ fontWeight: '400', fontSize: '12px', padding: '4px 8px' }}>
                                Booster coefficient {multiplierCNTP}x / 12s / Guardian
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        <Stack alignItems="center" width={'100%'}>
                          <Typography width={'100%'} textAlign={'left'} sx={{ fontWeight: '700', fontSize: '16px', mb: '12px' }}>Total</Typography>
                          {!isLoadingPrices ?
                            <Box display={'flex'} gap={2} alignItems={'center'}>
                              <Typography fontSize={[20, 40, 60]}>{selectedCoin == 'none'
                                ? 1250
                                : Number.isInteger(guardianQuant * guardianPriceByCoin)
                                  ? (guardianQuant * guardianPriceByCoin)
                                  : (guardianQuant * guardianPriceByCoin).toFixed(4)
                              }</Typography>
                              <Typography fontSize="26px">{displayCoin.toUpperCase()}</Typography>
                            </Box>
                            : <Skeleton variant="rectangular" width={'100%'} height={85} />}
                        </Stack>

                        <Box display={'flex'} alignItems={'center'} gap={2} margin={'0 auto'} justifyContent={'center'} mt={'16px'}>
                          <Select
                            id="coin-select"
                            defaultValue={'select-token'}
                            label='ETH'
                            onChange={handleChange}
                            IconComponent={KeyboardArrowDownIcon}
                            variant="standard"
                            disableUnderline={true}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textDecoration: 'none',
                              width: '100%',
                              maxWidth: '180px',
                            }}
                          >

                            <MenuItem value="select-token" disabled style={{ display: "none" }}>Select your Token</MenuItem>

                            <ListSubheader key={`subheader-aeth`} style={{
                              padding: '8px', fontSize: '14px', lineHeight: '14px', background: "none"

                            }}>Arbitrum Network</ListSubheader>
                            {parseFloat(userProfile?.tokens?.arb_usdt?.balance) >= (totalPrice ? totalPrice : 0) && <MenuItem value={`${userProfile?.tokens?.arb_usdt?.network}-${userProfile?.tokens?.arb_usdt?.name}`}><img width={'25px'} src={usdtBnb} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />USDT</MenuItem>}

                            {(parseFloat(userProfile?.tokens?.arb_usdt?.balance) == 0 || parseFloat(userProfile?.tokens?.arb_eth?.balance) == 0) && <ListSubheader key={`insuficient-blast`} style={{
                              fontSize: '12px', lineHeight: '14px', background: "none", paddingBottom: "8px"
                            }}>Insufficient funds</ListSubheader>}

                            {parseFloat(userProfile?.tokens?.arb_eth?.balance) >= (totalPrice ? totalPrice : 0) && <MenuItem value={`${userProfile?.tokens?.arb_eth?.network}-${userProfile?.tokens?.arb_eth?.name}`}><img width={'25px'} src={eth} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />ETH</MenuItem>}

                            {(parseFloat(userProfile?.tokens?.arb_eth?.balance) == 0 || parseFloat(userProfile?.tokens?.arb_eth?.balance) == 0) && <ListSubheader key={`insuficient-blast`} style={{
                              fontSize: '12px', lineHeight: '14px', background: "none", paddingBottom: "8px"
                            }}>Insufficient funds</ListSubheader>}
                            <ListSubheader key={`subheader-bnb`} style={{
                              padding: '8px', fontSize: '14px', lineHeight: '14px', background: "none"

                            }}>Binance Network</ListSubheader>
                            {parseFloat(userProfile?.tokens?.wusdt?.balance) >= (totalPrice ? totalPrice : 0) && <MenuItem value={`${userProfile?.tokens?.wusdt?.network}-${userProfile?.tokens?.wusdt?.name}`}><img width={'25px'} src={usdtBnb} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />USDT</MenuItem>}
                            {/* {parseFloat(profile?.tokens?.bnb.balance) > 0 && <MenuItem value="bsc-bnb"><img width={'25px'} src={bnbBnb} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px', height: '25px', width: '25px' }} />BNB</MenuItem>} */}
                            {parseFloat(userProfile?.tokens?.wusdt?.balance) == 0 && parseFloat(userProfile?.tokens?.bnb?.balance) == 0 && <ListSubheader key={`insuficient-blast`} style={{
                              fontSize: '12px', lineHeight: '14px', background: "none", paddingBottom: "8px"

                            }}>Insufficient funds</ListSubheader>}

                            {parseFloat(userProfile?.tokens?.bnb?.balance) >= (totalPrice ? totalPrice : 0) && <MenuItem value={`${userProfile?.tokens?.wbnb?.network}-${userProfile?.tokens?.wbnb?.name}`}><img width={'25px'} src={bnbBnb} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />BNB</MenuItem>}
                            {/* {parseFloat(profile?.tokens?.bnb.balance) > 0 && <MenuItem value="bsc-bnb"><img width={'25px'} src={bnbBnb} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px', height: '25px', width: '25px' }} />BNB</MenuItem>} */}
                            {parseFloat(userProfile?.tokens?.bnb?.balance) == 0 && parseFloat(userProfile?.tokens?.bnb?.balance) == 0 && <ListSubheader key={`insuficient-blast`} style={{
                              fontSize: '12px', lineHeight: '14px', background: "none", paddingBottom: "8px"

                            }}>Insufficient funds to the gasFee</ListSubheader>}

                            <ListSubheader key={`subheader-eth`} style={{
                              padding: '8px', fontSize: '14px', lineHeight: '14px', background: "none"

                            }}>ETH Network</ListSubheader>

                            {parseFloat(userProfile?.tokens?.usdt?.balance) >= (totalPrice ? totalPrice : 0) && <MenuItem value={`${userProfile?.tokens?.usdt?.network}-${userProfile?.tokens?.usdt?.name}`}><img width={'25px'} src={pay} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />USDT</MenuItem>}
                            {parseFloat(userProfile?.tokens?.eth?.balance) == 0 && parseFloat(userProfile?.tokens?.usdt?.balance) == 0 && <ListSubheader key={`insuficient-blast`} style={{
                              fontSize: '12px', lineHeight: '14px', background: "none", paddingBottom: "8px"

                            }}>Insufficient funds to the gasFee</ListSubheader>}

                            {parseFloat(userProfile?.tokens?.eth?.balance) >= (totalPrice ? totalPrice : 0) && <MenuItem value={`${userProfile?.tokens?.eth?.network}-${userProfile?.tokens?.eth?.name}`}><img width={'25px'} src={eth} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />ETH</MenuItem>}
                            {parseFloat(userProfile?.tokens?.eth?.balance) == 0 && parseFloat(userProfile?.tokens?.eth?.balance) == 0 && <ListSubheader key={`insuficient-blast`} style={{
                              fontSize: '12px', lineHeight: '14px', background: "none", paddingBottom: "8px"

                            }}>Insufficient funds to the gasFee</ListSubheader>}

                            {/* <ListSubheader key={`subheader-eth`} style={{
                              padding: '8px', fontSize: '14px', lineHeight: '14px', background: "none"

                            }}>CONET Holesky</ListSubheader> */}
                            {/* {parseFloat(profile?.tokens?.dWETH.balance) > 0 && <MenuItem value="holesky-dWETH"><img width={'25px'} src={eth} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />dWETH</MenuItem>}
                            {parseFloat(profile?.tokens?.dWBNB.balance) > 0 && <MenuItem value="holesky-dWBNB"><img width={'25px'} src={bnbBnb} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />dWBNB</MenuItem>} */}
                            {/* {parseFloat(profile?.tokens?.dUSDT.balance) > 0 && <MenuItem value="holesky-dUSDT"><img width={'25px'} src={pay} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px' }} />dUSDT</MenuItem>} */}
                          </Select>
                        </Box>
                      </Stack>
                      <Box marginTop={'48px'} borderRadius='16px' padding='8px' display={'flex'} flexDirection={'column'} justifyContent={'center'} alignContent={'center'} bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#111113"} style={{ cursor: 'pointer' }}>
                        <Box display="flex" gap="8px" alignItems="center" justifyContent="center" onClick={() => setIsAgreementOpen(!isAgreementOpened)}>
                          <Typography textAlign='center' color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"} >Agreement</Typography>
                          <SvgIcon style={{ color: localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF" }} component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                        </Box>
                        {isAgreementOpened &&
                          <>
                            <Stack style={{ maxHeight: '320px', overflowY: 'scroll', overflowX: 'hidden' }} fontSize={'14px'} padding={'8px 24px'} gap={2} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                              <Typography fontWeight={700}>IGO (Initial Guardian Offering) AGREEMENT</Typography>
                              <Typography>This IGO Agreement (the "Agreement") is entered into as of Mar 20, 2024, by and CoNET Network Foundation (hereinafter referred to as the "Issuer"), and the undersigned public participant (hereinafter referred to as the "Participant").</Typography>
                              <Typography fontWeight={700}>1. DEFINITIONS</Typography>
                              <Typography>1.1 "IGO" refers to the Initial Guardian Offering conducted by the Issuer for the sale of its CoNET Guardian Plan.</Typography>
                              <Typography>1.2 "CNTP" and "CoNET Super Node" refer to the digital assets issued by the Issuer during the IGO.</Typography>
                              <Typography fontWeight={700}>2. PARTICIPATION</Typography>
                              <Typography>2.1 The Participant acknowledges and agrees to participate in the IGO and purchase Guardian Plan at the price specified in the IGO official CoNET Platform V2.0.</Typography>
                              <Typography>2.2 The Participant acknowledges that the participation in the IGO carries inherent risks, and the Participant is solely responsible for conducting its own research before participating.</Typography>
                              <Typography fontWeight={700}>3. Guardian Plan PRICE AND PAYMENT</Typography>
                              <Typography>3.1 The price per Guardian Plan during the IGO is set at USDT$1,250 per Guardian Plan. Total 20,000 Guardian Plans. Payables in USDT/USDB/BUSD/ETH/BNB.</Typography>
                              <Typography>3.2 During the IGO phase, Guardian Plans are available for presale to users worldwide, including all CoNET community members. Certain community users who purchase Guardian Plan on the official CoNET Platform are entitled to a one-year repurchase agreement.</Typography>
                              <Typography>3.3  The project team is committed to ensuring the investment security of purchasers of the "Guardian Plan" and promises a repurchase at cost.</Typography>
                              <Typography>After one-year successful IGO purchase, the Guardian Plan buyer can execute a repurchase agreement through a smart contract at 100% of the purchase price in USDT assets.</Typography>
                              <Typography>Repurchase Lock-up Period: Within one year from the date of purchase of the "Guardian Plan".</Typography>
                              <Typography>During the repurchase lock-up period, if the CoNET token ($CONET) is successfully listed on one of the exchanges such as Coinbase, Binance, OKX, or BitGet, Guardians can be given a 7-day cooling-off period to decide whether they need to be repurchased. If the cooling-off period has passed and the Guardians have not activated the repurchase, the system will automatically assume that the Guardians have waived the right to be repurchased.</Typography>
                              <Typography>After activation for repurchase, all related Guardian rights automatically disappear, but CoNET tokens ($CONET) that are exchanged for referral points ($CNTP), Blast airdrop tokens, and CoNET portable hardware mining machines can be retained. CoNET tokens ($CONET) that are exchanged for super node points ($CNTP) will be destroyed. Super nodes will return to the ownership of the CoNET project. Portable hardware mining machines can continue to participate in POS mining, but the hash rate will be the same as that of standard users.</Typography>
                              <Typography>3.4 Neither party has the authority to propose changes of this repurchase agreement. Once the agreement is established and confirmed, the smart contract for the IGO sale and repurchase process will be automatically executed.</Typography>
                              <Typography>3.5 IGO is an early-stage venture for the project, and both the subscriber and repurchaser voluntarily engage in this activity. Once the contract is executed, subsequent processes will be automatically carried out by smart contract. The return on investment for all IGO users may fluctuate once the token is priced on the market.</Typography>
                              <Typography>3.6 The Participant agrees to pay the total amount in full at the time of participation.</Typography>
                              <Typography fontWeight={700}>4. RESPONSIBILITIES OF THE ISSUER</Typography>
                              <Typography>4.1 The Issuer shall use its best efforts to conduct the IGO in a fair and transparent manner.</Typography>
                              <Typography>4.2 The Issuer makes no guarantees regarding the future value of the Tokens, and the Participant acknowledges the speculative nature of investing in digital assets.</Typography>
                              <Typography fontWeight={700}>5. RISKS</Typography>
                              <Typography>5.1 The Participant acknowledges that the purchase of Guardian Plans involves risks, including but not limited to market risks, regulatory risks, and technology risks.</Typography>
                              <Typography>5.2 The Participant understands and accepts that the value of Guardian Plans may fluctuate, and there is a risk of losing the entire investment.</Typography>
                              <Typography fontWeight={700}>6. COMPLIANCE WITH LAWS</Typography>
                              <Typography>6.1 The Participant agrees to comply with all applicable laws and regulations in their jurisdiction regarding the purchase and possession of Guardian Plans.</Typography>
                              <Typography fontWeight={700}>7. DISCLAIMERS</Typography>
                              <Typography>7.1 The Issuer disclaims any warranties, express or implied, regarding the Guardian Plans, Super Node and the IGO, including but not limited to merchantability and fitness for a particular purpose.</Typography>
                              <Typography fontWeight={700}>8. MISCELLANEOUS</Typography>
                              <Typography>8.1 This Agreement constitutes the entire understanding between the parties and supersedes all prior agreements.</Typography>
                              <Typography>8.2 Amendments to this Agreement must be in writing and signed by both parties.</Typography>
                              <Typography>IN WITNESS WHEREOF, the parties hereto have executed this IGO Agreement as of the date first above written.</Typography>
                              <Typography>CoNET Foundation</Typography>
                              <Typography>Mar 20, 2024</Typography>

                              <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                                <Checkbox checked={isAgreementSigned} onChange={() => setIsAgreementSigned(!isAgreementSigned)} ></Checkbox>
                                <Typography style={{ fontSize: '12px', marginTop: '5px', cursor: 'pointer' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>I agree with the terms and conditions above</Typography>
                              </Box>
                            </Stack>
                          </>
                        }


                      </Box>

                      <Stack flex="1" direction="column" justifyContent="flex-end">
                        <PurchaseButtom
                          style={{
                            width: '100%',
                            textAlign: "center",
                            maxWidth: '300px',
                            margin: '0 auto',
                            marginTop: '32px',
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                            fontSize: "15px",
                            padding: "0.65rem",
                            borderRadius: "0.75rem",
                            cursor: "pointer",
                            backgroundColor: circleDevider
                          }}

                          disabled={!isAgreementSigned && selectedCoin != 'none'}
                          onClick={async () => {
                            if (selectedCoin == 'none') {
                              toast.error('Please select the desired token', { position: "bottom-center", autoClose: 1500 })
                            } else {
                              setPurchaseMode('crypto')
                              setIsLoading(true)
                              setTimer(60);
                              setTimeout(() => {
                                confirmPrePurchase();
                              }, 2000)
                            }
                          }}
                        >
                          {!isLoading ? 'Purchase' : <CircularProgress size={24} color="inherit" />}
                        </PurchaseButtom>

                        {/* <PurchaseButtom
                          style={{
                            width: '100%',
                            textAlign: "center",
                            maxWidth: '300px',
                            margin: '0 auto',
                            marginTop: '16px',
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                            fontSize: "15px",
                            padding: "4px",
                            borderRadius: "0.75rem",
                          }}
                          disabled={!isAgreementSigned}
                          onClick={async () => {
                            setIsLoadingFX(true)
                            confirmFXPrePurchase()
                            await fxBuy()
                          }}
                        >
                          {!isLoadingFX ?
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} gap={1}>
                              <Typography style={{ fontWeight: '700' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>Pay with</Typography>
                              <img height={'28px'} src={fx168} />
                            </Box>
                            : <CircularProgress size={24} color="inherit" />
                          }
                        </PurchaseButtom> */}

                        <Stack flexDirection={'row'} justifyContent={'center'} marginTop={'12px'} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                          <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                          Secure payment
                        </Stack>
                      </Stack>
                    </>
                  )}
                {(isBuying && purchaseMode == 'fx168') && (
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'space-around' }} gap={8}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "320px" }} gap={2}>
                      <Box sx={{ maxWidth: '160px', width: '100%', height: "160px", borderRadius: "50%", border: "5px solid white", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img width={'100px'} src={waitingTX} alt='success' width='32px' />
                      </Box>
                      <Typography style={{ fontSize: '20px', textAlign: "center" }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "white"}>Copy your Order Number</Typography>
                      <Typography style={{ fontSize: '14px', textAlign: "center" }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Complete your purchase by copying the Order Number below, then paste it in the Fx168 page.</Typography>
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={4}>
                      <div
                        style={{
                          display: `flex`,
                          color: theme.ui.colors.primary,
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          CopyToClipboard(txCode)}
                      >
                        <Typography style={{ fontSize: '14px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#3399FF"}>{txCode}</Typography>
                        <MdOutlineContentCopy style={{ marginLeft: '4px' }} />
                      </div>
                      <Typography style={{ fontSize: '14px', padding: '16px 32px', background: "rgba(247, 189, 37, 0.05)", borderRadius: '8px', marginTop: '32px' }} color={"#F5B904"}>Select {guardianQuant} nodes in the FX168 page</Typography>

                      <Typography style={{ fontSize: '14px', textAlign: "center", cursor: "pointer" }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#3399FF"} onClick={async () => { setPurchaseMode(''); setIsBuying(false); setTotalPrice(undefined); setConfirm(false) }}>Dismiss</Typography>
                      <RedirectButtom style={{
                        width: '100%',
                        textAlign: "center",
                        maxWidth: '300px',
                        margin: '0 auto',
                        marginTop: '16px',
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "600",
                        fontSize: "15px",
                        padding: "16px",
                        borderRadius: "0.75rem",
                      }}
                        href="https://cashier.alphapay.com/commodity/details/order/113/100000071"
                        target="_blank">
                        Access Fx168 payment page
                      </RedirectButtom>
                    </Box>
                  </Box>
                )}
                {(isBuying && purchaseMode == 'crypto' && totalPrice && !confirm && !buyingLoading) && (
                  <Box>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '0px' }}>
                      <img
                        src={leftArrow}
                        alt='back'
                        height={30}
                        width={30}
                        style={{ cursor: 'pointer' }}
                        onClick={() => { setIsBuying(false); setTotalPrice(undefined) }}
                      />
                      <Typography
                        fontSize="24px"
                        color="#8DA8FF"
                        fontWeight={'700'}
                      >
                        Confirm your order
                      </Typography>
                    </div>

                    <div>
                      <Typography style={{ marginBottom: '8px', marginTop: '40px', fontWeight: 700, fontSize: '16px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Paying with</Typography>
                      <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="16px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>

                        <Typography variant="h6" sx={{ fontWeight: '400', fontSize: '20px', color: '#fff' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>

                            <img src={coinImage} alt="pay-icon" style={{ cursor: 'pointer', marginRight: '10px', height: '25px', width: '25px' }} />

                            <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>
                              {displayCoin.toUpperCase()}
                            </Typography>
                          </div>
                        </Typography>

                      </Box>
                      <Typography style={{ marginBottom: '8px', marginTop: '40px', fontWeight: 700, fontSize: '16px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Wallet</Typography>
                      <Box style={{ cursor: "pointer" }} display="flex" alignItems="center" justifyContent="space-between" padding="8px 32px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>
                        <Box display="flex" gap="8px" alignItems="center">
                          <Box display="flex" flexDirection="column">
                            <Typography variant="subtitle1" fontSize={'14px'} fontWeight={'500'} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>
                              {currentProfile?.data?.nickname || intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' })}
                            </Typography>
                            <Typography variant="body2" fontSize={'12px'} color={'#5F5E60'}>
                              {currentProfile?.keyID?.substring(0, 2) + currentProfile?.keyID?.substring(2, 8).toUpperCase() + '...' + currentProfile?.keyID?.substring(currentProfile?.keyID?.length - 6, currentProfile?.keyID?.length).toUpperCase()}
                            </Typography>
                          </Box>
                        </Box>
                        {/* <Box display="flex" gap="8px" alignItems="center" justifyContent="center">
                          <SvgIcon component={KeyboardArrowDownIcon} inheritViewBox sx={{ fontSize: 16 }} />
                        </Box> */}
                      </Box>
                      <Box display='flex' alignItems='center' width='100%' gap={{ xs: 10, sm: 34 }}>
                        <Typography style={{ marginBottom: '8px', marginTop: '40px', fontWeight: 700, fontSize: '16px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Summary</Typography>
                        <Box display='flex' alignItems='center' justifyContent='center' position='relative'>
                          <img src={clock} height='16px' style={{ position: "absolute", bottom: '8.5px', left: "-20px", }} />
                          <Typography style={{ marginBottom: '8px', marginTop: '40px', fontWeight: 400, fontSize: '12px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Quote updates in <span style={{ fontWeight: 700 }}>{formatTime(timer)}</span></Typography>
                        </Box>

                      </Box>

                      <Box style={{ cursor: "pointer" }} display="flex" flexDirection='column' gap='16px' justifyContent="space-between" padding="16px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>
                        <Box display='flex' justifyContent='space-between'>
                          <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>{isSelectedPlan.charAt(0).toUpperCase() + isSelectedPlan.slice(1)} Plan</Typography>
                          <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>{guardianQuant * guardianPriceByCoin}  {displayCoin.toUpperCase()}</Typography>
                        </Box>

                        <Box display='flex' justifyContent='space-between'>
                          <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>GAS fee</Typography>
                          <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>{!isNaN(gasFee) ? gasFee : "Not Available"} {!isNaN(gasFee) ? asset.toUpperCase() : ""}</Typography>
                        </Box>
                        <hr />
                        <Box display='flex' justifyContent='space-between'>
                          <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 700, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>Total</Typography>
                          {!isNaN(gasFee) ?
                            asset.toUpperCase() === selectedCoin.toUpperCase() ? <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 700, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>{(guardianPriceByCoin * guardianQuant) + gasFee} {displayCoin.toUpperCase()}</Typography> :
                              <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 700, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}> {(guardianPriceByCoin * guardianQuant)} {displayCoin.toUpperCase()} {` + ${gasFee}`} {`${asset.toUpperCase()}`}</Typography>
                            :
                            <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 700, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}> - </Typography>
                          }
                        </Box>
                      </Box>
                    </div>


                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '164px' }}>
                      <PurchaseButtom
                        style={{
                          width: '100%',
                          textAlign: "center",
                          maxWidth: '300px',
                          margin: '0 auto',
                          marginTop: '32px',
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontSize: "15px",
                          padding: "0.65rem",
                          borderRadius: "0.75rem",
                          cursor: "pointer",
                        }}
                        disabled={!gasFee}
                        onClick={async () => {
                          setBuyingLoading(true);
                          setConfirm(true);
                          await buyGuardian()
                          setBuyingLoading(false);
                        }}
                      >
                        {!isNaN(gasFee) ?
                          "Confirm Payment"
                          :
                          "Unable to get the GasFee"
                        }
                      </PurchaseButtom>
                      <Stack flexDirection={'row'} justifyContent={'center'} marginTop={'12px'} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                        <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                        Secure payment
                      </Stack>
                    </div>

                  </Box>
                )}
                {(buyingLoading && purchaseMode == 'crypto' && purchaseStatus >= 0 && purchaseStatus < 4) && (
                  <Box>
                    <p style={{ color: ' #8DA8FF', fontWeight: 700, fontSize: '24px', marginBottom: '24px' }}> Transaction in progress</p>
                    <p style={{ color: '#C8C6C8', fontWeight: 400, fontSize: '14px' }}>Your order completion time may vary, please wait</p>
                    <p style={{ color: '#C8C6C8', fontWeight: 400, fontSize: '14px' }}>and we’ll let you know when it’s completed.</p>
                    <Stack marginTop={'110px'} height={'190px'} flexDirection={'row'} justifyContent={'center'} position={'relative'}>
                      <img className="rotationClock" src={dentro} width={'96px'} height={'96px'} style={{ animation: 'rotation 2s infinite linear', top: 'calc(50% - 48px)', position: "absolute", display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} />
                      <img className="rotation" src={fora} width={'144px'} height={'144px'} style={{ top: 'calc(50% - 72px)', position: "absolute", display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} />
                    </Stack>

                    {purchaseStatus == 0 && <p style={{ color: '#C8C6C8', fontWeight: 600, textAlign: 'center' }}>Starting Guardian Purchase...</p>}
                    {purchaseStatus == 1 && <p style={{ color: '#C8C6C8', fontWeight: 600, textAlign: 'center' }}>Transfering asset to CONET Guardian...</p>}
                    {purchaseStatus == 2 && <p style={{ color: '#C8C6C8', fontWeight: 600, textAlign: 'center' }}>Waiting transfering confirmation...</p>}
                    {purchaseStatus == 3 && <p style={{ color: '#C8C6C8', fontWeight: 600, textAlign: 'center' }}>Finalizing purchase...</p>}


                    <p style={{ color: '#C8C6C8', fontWeight: 500, fontSize: '11px', marginTop: '110px', textAlign: 'center', lineHeight: '16px', letterSpacing: '0.5px' }}>The purchase process can take up to 24 hours, wait until</p>
                    <p style={{ color: '#C8C6C8', fontWeight: 500, fontSize: '11px', textAlign: 'center', lineHeight: '16px', letterSpacing: '0.5px' }}>it’s finished, when it’s finished you’ll be notified.</p>
                    <Stack>
                      <PurchaseButtom
                        style={{
                          width: '100%',
                          textAlign: "center",
                          maxWidth: '300px',
                          margin: '0 auto',
                          marginTop: '32px',
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontSize: "15px",
                          padding: "0.65rem",
                          borderRadius: "0.75rem",
                          cursor: "pointer",

                        }}
                      >
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={1}>
                          <CircularProgress size={24} color="inherit" /> Processing
                        </Box>

                      </PurchaseButtom>
                    </Stack>


                    <Stack flexDirection={'row'} justifyContent={'center'} marginTop={'12px'} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                      <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                      Secure payment
                    </Stack>
                  </Box>
                )}

                {purchaseStatus < 0 && (
                  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }} gap={8}>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "320px" }} gap={2}>
                      <Box sx={{ maxWidth: '160px', width: '100%', height: "160px", borderRadius: "50%", border: "5px solid white", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={failure} alt='success' width='32px' />
                      </Box>
                      <Typography style={{ fontSize: '20px', textAlign: "center" }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFB4AB"}>Purchase declined by CoNET</Typography>
                      <Typography style={{ fontSize: '14px', textAlign: "center" }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>Please, send us a message</Typography>
                    </Box>
                    {/*                     <Box>
                      <Typography style={{ fontSize: '14px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#3399FF"}>0x412FJK...B3AB46</Typography>
                    </Box> */}
                    <Box>
                      <Typography style={{ fontSize: '14px', cursor: 'pointer' }} onClick={async () => { setPurchaseMode(''); setIsBuying(false); setSelectedCoin('none'); setConfirm(false); setPurchaseStatus(0); setTotalPrice(1250); setGuardianQuant(1) }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#3399FF"}>Dismiss</Typography>
                    </Box>
                  </Box>
                )}

                {(isBuying && totalPrice && confirm && purchaseMode == 'crypto' && (purchaseStatus > 3)) && (
                  <Box>
                    <Box>
                      <img src={img5} alt='success' width='32px' />
                      <Typography style={{ fontSize: '36px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}>The transaction </Typography>
                      <p>
                        <span style={{ color: ' #8DA8FF', fontWeight: 700, fontSize: '36px' }}> was successful </span>
                      </p>
                    </Box>
                    <div>
                      <Box marginTop={'80px'} style={{ cursor: "pointer" }} display="flex" flexDirection='column' gap='16px' justifyContent="space-between" padding="16px" borderRadius="16px" bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"}>
                        <Box display='flex' justifyContent='space-between'>
                          <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>{isSelectedPlan.charAt(0).toUpperCase() + isSelectedPlan.slice(1)} Plan</Typography>
                          <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>{guardianQuant * guardianPriceByCoin}  {displayCoin.toUpperCase()}</Typography>
                        </Box>

                        <Box display='flex' justifyContent='space-between'>
                          <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>GAS fee</Typography>
                          <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>{parseFloat(gasFee)} {asset.toUpperCase()}</Typography>
                        </Box>
                        <hr />
                        <Box display='flex' justifyContent='space-between'>
                          <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 700, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>Total</Typography>
                          {!isNaN(gasFee) ?
                            <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 700, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>{parseFloat(gasFee)} {asset.toUpperCase()} {(guardianPriceByCoin * guardianQuant)} {displayCoin.toUpperCase()}</Typography>
                            :
                            <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 700, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}> - </Typography>
                          }
                          {/* <Typography style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 700, letterSpacing: '0.25px' }} color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : '#F6F1F2'}>{parseFloat(gasFee) + (guardianQuant * guardianPriceByCoin)} {selectedCoin.toUpperCase()}</Typography> */}
                        </Box>
                      </Box>
                      <Box bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#F6F8FF" : "#303032"} style={{ borderRadius: '16px', padding: '8px 16px', display: 'flex', alignItem: "center", gap: '24px', marginTop: '100px' }}>

                        <Stack position={'relative'}>
                          <img src={renderPlanLogo()} width={70} style={{ borderRadius: '50%' }} />
                          <img src={conetLogo3d} width={60} style={{ position: 'absolute', zIndex: 2 }} />
                        </Stack>

                        <Typography fontSize={['18px', "24px"]} fontWeight="400" lineHeight='32px' color={localStorage.getItem('mui-mode') === 'light' ? "#111113" : "#FFFFFF"}><span style={{ fontWeight: 'bold' }}>Congratulations! </span>You now have <span style={{ color: ' #8DA8FF', fontWeight: 700 }}>{guardianQuant}</span> {isSelectedPlan} nodes.</Typography>
                      </Box>
                      <div style={{ marginTop: '32px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography style={{ color: ' #8DA8FF', fontWeight: 700, cursor: 'pointer' }} onClick={() => <ProfileTest />}>
                          Go to profile
                        </Typography>
                        <PurchaseButtom
                          style={{
                            width: '100%',
                            textAlign: "center",
                            maxWidth: '300px',
                            margin: '8px auto',
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                            fontSize: "15px",
                            padding: "0.65rem",
                            borderRadius: "0.75rem",
                            cursor: "pointer",
                          }}
                          onClick={() => { setIsBuying(false); setTotalPrice(undefined); setConfirm(false) }}
                        >
                          Make new one
                        </PurchaseButtom>
                        <Stack flexDirection={'row'} justifyContent={'center'} color={localStorage.getItem('mui-mode') === 'light' ? "#5F5E60" : "#FFFFFF"}>
                          <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                          Secure payment
                        </Stack>
                      </div>

                    </div>

                  </Box>
                )}


              </SuperNodeBannerBuyCardBuying>
            </Box>
          </SuperNodeBannerContent >
        </Box>
      </Box >
      <SuperNodeBannerFooter style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center', textAlign: 'center', flexWrap: 'wrap' }}>
        <CardNodeBannerFooter>
          <BannerIcon src={img1} />
          <Typography fontSize="14px" fontWeight="600" height={'42px'}>
            {intl.formatMessage({
              id: "platform.miner.community.superNodeBanner.icon.scalability",
            })}
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={img2} />
          <Typography fontSize="14px" fontWeight="600" height={'42px'}>
            {intl.formatMessage({
              id: "platform.miner.community.superNodeBanner.icon.global",
            })}
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={img3} />
          {/* <Stack direction="row" alignItems="center">
          </Stack> */}
          <Typography fontSize="14px" fontWeight="600" height={'42px'}>
            {intl.formatMessage({
              id: "platform.miner.community.superNodeBanner.icon.maintenance",
            })}
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={img4} />
          <Typography fontSize="14px" fontWeight="600" height={'42px'}>

            {intl.formatMessage({
              id: "platform.joinUS.miner.cloud.table.item4",
            })}
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={img1} />
          <Typography fontSize="14px" fontWeight="600" height={'42px'}>
            Receive Special CoNET Airdrop
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={cloudImage} />
          <Typography fontSize="14px" fontWeight="600" height={'42px'}>
            Own Super Cloud Nodes
          </Typography>
        </CardNodeBannerFooter>
      </SuperNodeBannerFooter>
    </SuperNodeBannerWrapper >
  );
};