import { useEffect, useState } from "react";
import {
  Box, Stack,
  Typography,
  SvgIcon,
  CircularProgress,
  Skeleton, ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { motion } from "framer-motion";
// import { getCode, getNames, getName } from 'country-list'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { platform } from "../../../../API/platform";

import guardianLogo from "../../../../assets/guardian.svg";

import infinityWhite from "../miner/assets/Infinity, loop, Repeat, Circle.svg";
import infintyBlack from "../miner/assets/Infinity, loop, Repeat, Circle-1.svg";

import { BridgeProfileDropdown } from "./bridgeProfileDropdown";
import { useTypedSelector } from "../../../../store/store";
import { Card } from "./card";
import { PurchaseButtom } from "../miner/styles/SuperNodeBanner";
import { useSilentPass } from "./useSilentPass";
import { useUserProfile } from "../../../../API/useUserProfile";
import { useRefreshStore } from "../../../../API/useRefreshStore";
import { toast } from "react-toastify";

import { savePurchase, getLastPurchase, updateIpAdress } from "./PurchasePouch";
import { localIpaddress } from "../../../../API";
import {
  OnlyMobileText
} from "../../NoDaemon/styles";
import { DownloadArea } from "../../NoDaemon";
import { locationMap } from "../../../../utilities/utilities";

import { useIsMiningRunning } from "../miner/pags/useIsMiningRunning";

const silentpassConfig = [
  {
    title: "MacOS",
    steps: [
      {
        text: "Open System Setting and select the connection type (Wi-fi or ethernet)",
        image: require("../../../../assets/silentpass/tutorial/MacOs/1.png"),
      },
      {
        text: "Click on details of the network you are connected to",
        image: require("../../../../assets/silentpass/tutorial/MacOs/2.png"),
      },
      {
        text: "Click on Proxies",
        image: require("../../../../assets/silentpass/tutorial/MacOs/3.png"),
      },
      {
        text: "Activate Automatic proxy configuration",
        image: require("../../../../assets/silentpass/tutorial/MacOs/4.png"),
      },
      {
        text: 'Type in URL: "http://localhost:3003/pac”, then click "OK"',
        image: require("../../../../assets/silentpass/tutorial/MacOs/5.png"),
      },
      {
        text: "All done, enjoy Silent Pass.",
        image: require("../../../../assets/silentpass/tutorial/MacOs/lastofall.png"),
      },
    ],
  },
  {
    title: "Windows",
    steps: [
      {
        text: "Open System Setting and go to Network & Internet, then click on Proxy",
        image: require("../../../../assets/silentpass/tutorial/Windows/1.png"),
      },
      {
        text: "Click “Set up” in Manual proxy setup",
        image: require("../../../../assets/silentpass/tutorial/Windows/2.png"),
      },
      {
        text: 'Activate "Use a proxy server", in "Proxy IP address" type "http://socks=localhost", in "Port" type "3003" and then save',
        image: require("../../../../assets/silentpass/tutorial/Windows/3.png"),
      },
      {
        text: "All done, enjoy Silent Pass.",
        image: require("../../../../assets/silentpass/tutorial/Windows/lastofall.png"),
      },
    ],
  },
  {
    title: "Firefox",
    steps: [
      {
        text: "Open the menu and go to settings",
        image: require("../../../../assets/silentpass/tutorial/Firefox/1.png"),
      },
      {
        text: 'Search for "Proxy"',
        image: require("../../../../assets/silentpass/tutorial/Firefox/2.png"),
      },
      {
        text: "Go to proxy settings",
        image: require("../../../../assets/silentpass/tutorial/Firefox/3.png"),
      },
      {
        text: "Select “Manual proxy settings” and in SOCKS Host, type “localhost” and “3003” in Port, select “SOCKS v5”",
        image: require("../../../../assets/silentpass/tutorial/Firefox/4.png"),
      },
      {
        text: "Select “DNS proxy when using SOCKS v5” and click “OK”",
        image: require("../../../../assets/silentpass/tutorial/Firefox/5.png"),
      },
      {
        text: "All done, enjoy Silent Pass.",
        image: require("../../../../assets/silentpass/tutorial/Firefox/lastofall.png"),
      },
    ],
  },
  {
    title: "Telegram",
    steps: [
      {
        text: "Go to Telegram Settings and search for “Proxy”",
        image: require("../../../../assets/silentpass/tutorial/Telegram/1.png"),
      },

      {
        text: 'Click on "Add Proxy"',
        image: require("../../../../assets/silentpass/tutorial/Telegram/2.png"),
      },
      {
        text: 'Click on "+ Add Proxy"',
        image: require("../../../../assets/silentpass/tutorial/Telegram/3.png"),
      },
      {
        text: 'In Proxy Type select SOCKS5, in connection type "localhost" in server and "3003" in port, then click in ”Done”',
        image: require("../../../../assets/silentpass/tutorial/Telegram/4.png"),
      },
      {
        text: "Wait to connect",
        image: require("../../../../assets/silentpass/tutorial/Telegram/5.png"),
      },
      {
        text: "All done, enjoy Silent Pass.",
        image: require("../../../../assets/silentpass/tutorial/Telegram/lastofall.png"),
      },
    ],
  },
  {
    title: "Chrome",
    steps: [
      {
        text: "Open the menu and go to the Chrome Web Store",
        image: require("../../../../assets/silentpass/tutorial/Chrome/1.png"),
      },

      {
        text: 'Search for "CONET Platform"',
        image: require("../../../../assets/silentpass/tutorial/Chrome/2.png"),
      },
      {
        text: 'Click on ”CONET Platform”',
        image: require("../../../../assets/silentpass/tutorial/Chrome/3.png"),
      },
      {
        text: 'Add the ”CONET Platform” to Google Chrome by clicking ”Add to Chrome”',
        image: require("../../../../assets/silentpass/tutorial/Chrome/4.png"),
      },
      {
        text: "Launch the extension, return to the CONET platform and go to the Silent Pass page",
        image: require("../../../../assets/silentpass/tutorial/Chrome/5.png"),
      },
      {
        text: "Start the extension and all done, enjoy Silent Pass.",
        image: require("../../../../assets/silentpass/tutorial/Chrome/lastofall.png"),
      },
    ],
  },
  {
    title: "Brave",
    steps: [
      {
        text: "Open the extension menu and go to the Web Store",
        image: require("../../../../assets/silentpass/tutorial/Brave/1.png"),
      },

      {
        text: 'Search for "CONET Platform"',
        image: require("../../../../assets/silentpass/tutorial/Brave/2.png"),
      },
      {
        text: 'Click on ”CONET Platform”',
        image: require("../../../../assets/silentpass/tutorial/Brave/3.png"),
      },
      {
        text: 'Add the ”CONET Platform” to Brave by clicking ”Add to Chrome”',
        image: require("../../../../assets/silentpass/tutorial/Brave/4.png"),
      },
      {
        text: "Launch the extension, return to the CONET platform and go to the Silent Pass page",
        image: require("../../../../assets/silentpass/tutorial/Brave/5.png"),
      },
      {
        text: "Start the extension and all done, enjoy Silent Pass.",
        image: require("../../../../assets/silentpass/tutorial/Brave/lastofall.png"),
      },
    ],
  },
];

export function Bridge() {
  const { purchaseTimestamp, setPurchaseTimestamp } = useSilentPass();

  const { userProfile, setUserProfile } = useUserProfile();
  const leaderboardStore = useRefreshStore((state) => state.leaderboards);
  const [seconds, setSeconds] = useState(calculateDifferenceInSeconds()); //calculateDifferenceInSeconds()
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isEntryNodeSelectOpen, setIsEntryNodeSelectOpen] = useState(false);
  const [isEgressNodeSelectOpen, setIsEgressNodeSelectOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProcessingSilentPassBuy, setIsProcessingSilentPassBuy] = useState<boolean>(false);
  const [isLoadingRegionChange, setIsLoadingRegionChange] =
    useState<boolean>(false);
  const [isLoadingUnlock, setLoadingUnlock] = useState<boolean>(false);

  const [buyTime, setBuyTime] = useState<string>("60");
  const [price, setPrice] = useState<number | undefined>(
    leaderboardStore
      ? Number(buyTime) * 60 * Number(leaderboardStore.minerRate)
      : 0
  );
  const [unlock, setUnlock] = useState<boolean>(false);
  const [isLoadingGasFee, setLoadingGasFee] = useState<boolean>(false);
  const [gasFee, setGasFee] = useState<string>("");
  const [nodeLocation, setNodeLocation] = useState<any>([]);
  const [address, setAddress] = useState("192.168.1.100");
  const [port] = useState<string>("3003");
  const [socksPort] = useState<string>("1080");
  const [selectedProxyOption, setSelectedProxyOption] = useState<number>(0);
  const [selectedEntryRegion, setSelectedEntryRegion] = useState<string>("US");
  const [selectedEgressRegion, setSelectedEgressRegion] =
    useState<string>("US");

  //selected Profile
  const [selectedProfile, setSelectedProfile] = useState<any>(
    userProfile ? userProfile : {}
  );

  const [conetPlatform, setConetPlatform] = useState<any>("");
  const [workerLoading, setWorkerLoading] = useState(0);
  const conet_platform = new platform(setConetPlatform, setWorkerLoading);

  const isMiningRunning = useIsMiningRunning((state) => state.isMiningRunning);
  const miningStatus = useIsMiningRunning((state) => state.miningStatus);
  const setMiningStatus = useIsMiningRunning((state) => state.setMiningStatus);

  useEffect(() => {
    async function returnPurchases() {
      const res = await getLastPurchase(selectedProfile.keyID);
      if (res !== undefined) {
        res?.purchaseTime
          ? setPurchaseTimestamp(res?.purchaseTime)
          : setPurchaseTimestamp(0);
        res?.ipAddress ? setAddress(res?.ipAddress) : setAddress("localhost");
        res?.entryNode ? setSelectedEntryRegion(res?.entryNode) : setSelectedEntryRegion('US')
        res?.exitNode ? setSelectedEgressRegion(res?.exitNode) : setSelectedEgressRegion('US')
        setSeconds(calculateDifferenceInSeconds()); //calculateDifferenceInSeconds()
      }
    }
    returnPurchases();
  }, [purchaseTimestamp, selectedProfile]);

  const profile = useTypedSelector((state) => state.appState.activeProfile);

  async function stopMining() {
    if (isMiningRunning) {
      await conet_platform.stopMining();
      useIsMiningRunning.getState().setIsMiningRunning(false);
      useIsMiningRunning.getState().toggleConnection(false);
    }
  }

  const circleDevider =
    localStorage.getItem("mui-mode") === "light" ? "#f6f8ff" : "#303032";

  async function handleUpdateRegion() {
    setIsLoadingRegionChange(true);

    const resStartSilentPass = await conet_platform.startSilentPass(
      selectedProfile,
      selectedEntryRegion,
      selectedEgressRegion
    );

    if (resStartSilentPass.status === "Confirmed") {
      const res = await localIpaddress();

      if (res && res.ip && res.ip !== null) {
        setAddress(res.ip);
        await updateIpAdress(selectedProfile.keyID, res.ip, selectedEntryRegion, selectedEgressRegion);
      } else {
        setAddress("localhost");
        await updateIpAdress(selectedProfile.keyID, "localhost", selectedEntryRegion, selectedEgressRegion);
      }
      setIsLoadingRegionChange(false);
      toast.success("Region Updated", {
        position: "bottom-center",
        autoClose: 1500,
      });
    } else {
      setIsLoadingRegionChange(false);
      toast.error("Internal server error", {
        position: "bottom-center",
        autoClose: 1500,
      });
    }
  }

  async function handlePurchase() {
    if (!price || isProcessingSilentPassBuy) return;

    setIsProcessingSilentPassBuy(true);

    const resBurnCCNTP = await conet_platform.burnCCNTP(
      selectedProfile,
      String(price)
    );

    const resStartSilentPass = await conet_platform.startSilentPass(
      selectedProfile,
      selectedEntryRegion,
      selectedEgressRegion
    );

    if (
      resBurnCCNTP.status === "Confirmed" &&
      resStartSilentPass.status === "Confirmed"
    ) {
      const res = await localIpaddress();
      if (res && res.ip && res.ip !== null) {
        setAddress(res.ip);
        savePurchase(
          selectedProfile.keyID,
          getFutureTimestamp(buyTime),
          res.ip,
          selectedEntryRegion,
          selectedEgressRegion
        );
      } else {
        setAddress("localhost");
        savePurchase(
          selectedProfile.keyID,
          getFutureTimestamp(buyTime),
          "localhost",
          selectedEntryRegion,
          selectedEgressRegion
        );
      }
      setPurchaseTimestamp(getFutureTimestamp(buyTime));
      setSeconds(calculateDifferenceInSeconds());
      setIsActive(true);
      toast.success("Silent Pass purchased", {
        position: "bottom-center",
        autoClose: 1500,
      });
    } else {
      toast.error("Internal server error", {
        position: "bottom-center",
        autoClose: 1500,
      });
    }

    setIsProcessingSilentPassBuy(false);
  }

  function handleChangeTime(t: string) {
    setBuyTime(t);
    // setPrice(Number(t) * Number(leaderboard.minerRate).toFixed(6))
  }

  async function updateGasFee() {
    setLoadingGasFee(true);
    const res = await conet_platform.preBurnCCNTP(
      selectedProfile,
      String(price)
    );
    if (res) {
      setGasFee(res);
    }
    setLoadingGasFee(false);
  }

  async function getRegions() {
    const allNodesRegions = await conet_platform.getGuardianRegion();

    const allNodesRegionsByCountry = new Set<string>();

    // return only the countries
    allNodesRegions?.forEach((region) => {
      const country = region?.split(".")[1];
      allNodesRegionsByCountry.add(country);
    });

    if (allNodesRegionsByCountry && allNodesRegionsByCountry?.size > 0) {
      setNodeLocation([...allNodesRegionsByCountry]);
    }
  }

  function getFutureTimestamp(hours: string) {
    const now = new Date();
    now.setHours(now.getHours() + Number(hours) / 60);
    const futureTimestamp = now.getTime();

    // remove this when test is finished and return futureTimestamp
    /*         const fiftyFourMinutesInMilliseconds = 54 * 60 * 1000;
                const resultTimestamp = futureTimestamp - fiftyFourMinutesInMilliseconds; */

    return futureTimestamp;
  }

  function calculateDifferenceInSeconds() {
    const now = new Date().getTime();
    const differenceInMillis = purchaseTimestamp - now;
    return Math.floor(differenceInMillis / 1000);
  }

  useEffect(() => {
    if (!leaderboardStore) return;

    setPrice(Number(buyTime) * 60 * Number(leaderboardStore.minerRate));
    updateGasFee();
    setIsLoading(false);
  }, [buyTime, leaderboardStore, purchaseTimestamp]);

  useEffect(() => {
    getRegions();
  }, []);

  /* useEffect(() => {
    updateGasFee();
  }, [price]); */

  useEffect(() => {
    let resultUnlock = selectedProfile?.tokens?.cCNTP?.unlocked;
    let resultBalance = selectedProfile?.tokens?.cCNTP?.balance > 0.001;
    setUnlock((resultUnlock && resultUnlock) || selectedProfile?.isNode);
  }, []);

  /* useEffect(() => {
    setPrice(
      leaderboardStore
        ? Number(buyTime) * 60 * Number(leaderboardStore.minerRate)
        : 1
    );
    updateGasFee();
  }, [leaderboardStore, purchaseTimestamp]); */

  useEffect(() => {
    let interval = null;
    if (isActive && unlock) {
      if (seconds === 0) {
        setSeconds(0);
        setIsActive(false);
        return;
      }
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, unlock, purchaseTimestamp]);

  const handleEntryRegionChange = async (selectedRegion: string) => {
    setSelectedEntryRegion(selectedRegion);
  };

  const handleEgressRegionChange = async (selectedRegion: string) => {
    setSelectedEgressRegion(selectedRegion);
  };

  const formatTime = (secs: any) => {
    if (secs <= 0) {
      return "00 : 00 : 00";
    }

    const getHours = Math.floor(secs / 3600);
    const getMinutes = Math.floor((secs % 3600) / 60);
    const getSeconds = secs % 60;

    const hours = String(getHours).padStart(2, "0");
    const minutes = String(getMinutes).padStart(2, "0");
    const secondsStr = String(getSeconds).padStart(2, "0");

    return `${hours}:${minutes}:${secondsStr}`;
  };

  const unlockWallet = async () => {
    setLoadingUnlock(true);
    const res = await conet_platform.unlock_cCNTP(selectedProfile);

    if (buyTime) setBuyTime(buyTime);
    if (!buyTime) setBuyTime("60");
    setUnlock(true);
    setLoadingUnlock(false);
  };

  const itemVariants: Variants = {
    open: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 300, damping: 24 },
    },
    closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
  };

  const handleProxyOptionChange = (
    event: React.MouseEvent<HTMLElement>,
    newOption: number
  ) => {
    if (newOption !== null) setSelectedProxyOption(newOption);
  };

  return (
    <Stack
      direction={"column"}
      width={"100%"}
      justifyContent={"center"}
      paddingTop={"16px"}
    >
      <Stack
        width={"100%"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        padding={"30px"}
        alignItems={"center"}
      >
        <Typography fontSize={36} fontWeight={700}>
          Silent Pass
        </Typography>
        <Typography fontSize={16} fontWeight={400} letterSpacing={0.5}>
          Learn More
        </Typography>
      </Stack>

      {/* TIMER AND TEXTS */}
      {selectedProfile.isNode === false ? (
        isActive ? (
          <Typography
            textAlign={"center"}
            fontSize={["60px", "112px"]}
            color={
              localStorage.getItem("mui-mode") === "light" ? "#000" : "#fff"
            }
          >
            {formatTime(seconds)}
          </Typography>
        ) : (
          <Typography
            textAlign={"center"}
            fontSize={["60px", "112px"]}
            color={
              localStorage.getItem("mui-mode") === "light"
                ? "#676768"
                : "#B1B1B2"
            }
          >
            {formatTime(seconds)}
          </Typography>
        )
      ) : (
        <Stack
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img
            src={
              localStorage.getItem("mui-mode") === "light"
                ? infintyBlack
                : infinityWhite
            }
            width={"300px"}
          />
        </Stack>
      )}

      {/* Ip Address, Port and Automatic Proxy Configuration */}
      <Stack marginBottom={"144px"}>
        <Stack
          display={"flex"}
          width={"100%"}
          alignItems={"center"}
          flexDirection={"row"}
          justifyContent={"center"}
          gap={8}
        >
          <Stack
            display={"flex"}
            alignItems={"flex-start"}
            flexDirection={"column"}
            justifyContent={"center"}
            gap={2}
          >
            <Stack
              display={"flex"}
              alignItems={"center"}
              flexDirection={"row"}
              justifyContent={"center"}
              gap={8}
            >
              <Stack>
                <Typography
                  fontSize={"14px"}
                  fontWeight={500}
                  color={
                    localStorage.getItem("mui-mode") === "light"
                      ? "#676768"
                      : "#b1b1b2"
                  }
                >
                  Server Address
                </Typography>
                <Typography
                  fontSize={"14px"}
                  fontWeight={400}
                  color={
                    localStorage.getItem("mui-mode") === "light"
                      ? "#0D0D0D"
                      : "#f6f1f2"
                  }
                >
                  {address}
                </Typography>
              </Stack>

              <Stack>
                <Typography
                  fontSize={"14px"}
                  fontWeight={500}
                  color={
                    localStorage.getItem("mui-mode") === "light"
                      ? "#676768"
                      : "#b1b1b2"
                  }
                >
                  Port Number
                </Typography>
                <Typography
                  fontSize={"14px"}
                  fontWeight={400}
                  color={
                    localStorage.getItem("mui-mode") === "light"
                      ? "#0D0D0D"
                      : "#f6f1f2"
                  }
                >
                  {port}
                </Typography>
              </Stack>
            </Stack>

            <Stack
              display={"flex"}
              alignItems={"center"}
              flexDirection={"row"}
              justifyContent={"center"}
              gap={8}
            >
              <Stack>
                <Typography
                  fontSize={"14px"}
                  fontWeight={500}
                  color={
                    localStorage.getItem("mui-mode") === "light"
                      ? "#676768"
                      : "#b1b1b2"
                  }
                >
                  Automatic Proxy Configuration
                </Typography>
                <Typography
                  fontSize={"14px"}
                  fontWeight={400}
                  color={
                    localStorage.getItem("mui-mode") === "light"
                      ? "#0D0D0D"
                      : "#f6f1f2"
                  }
                >
                  http://localhost:3003/pac
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Divider
        sx={{ width: "80%", margin: "auto", borderBottomWidth: "1.5px" }}
      />

      <Stack
        sx={{ width: "80%", margin: "auto", maxWidth: ["100%", "1040px"] }}
      >
        <Typography
          fontSize={"16px"}
          fontWeight={500}
          color={
            localStorage.getItem("mui-mode") === "light" ? "#0D0D0D" : "#f6f1f2"
          }
          margin={"8px 0px"}
        >
          Select Account
        </Typography>

        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"8px"}
          justifyContent={"space-between"}
        >
          <Stack
            flexDirection={["column", "row"]}
            gap={"8px"}
            justifyContent={"space-between"}
          >
            <Stack width={["100%", "50%"]}>
              <BridgeProfileDropdown
                setSelectedProfile={setSelectedProfile}
                selectedProfile={selectedProfile}
              />
            </Stack>
            <Box
              width={["100%", "50%"]}
              padding={"16px"}
              height={"76px"}
              bgcolor={
                localStorage.getItem("mui-mode") === "light"
                  ? "#f8f8fa"
                  : "#191919"
              }
              borderRadius={"16px"}
            >
              <Typography
                fontSize={"14px"}
                fontWeight={400}
                color={
                  localStorage.getItem("mui-mode") === "light"
                    ? "#676768"
                    : "#b1b1b2"
                }
              >
                Your available balance
              </Typography>
              <Typography>
                {selectedProfile.tokens?.cCNTP?.balance} CNTP
              </Typography>
            </Box>
          </Stack>

          {unlock ? (
            seconds <= 0 ? (
              // start silent pass
              <Stack>
                {selectedProfile && selectedProfile.isNode === false ? (
                  <Typography
                    fontSize={"24px"}
                    fontWeight={700}
                    color={
                      localStorage.getItem("mui-mode") === "light"
                        ? "#0D0D0D"
                        : "#f6f1f2"
                    }
                    margin={"8px 0px"}
                    textAlign={"center"}
                  >
                    Burn CNTP and get Silent Pass time
                  </Typography>
                ) : null}

                {selectedProfile.isNode === false ? (
                  // non-node users
                  <Stack flexDirection={"column"} gap={"8px"}>
                    <Stack
                      flexDirection={["column", "row"]}
                      justifyContent={"space-between"}
                      gap={"8px"}
                    >
                      <Stack width={["100%", "50%"]}>
                        <Typography
                          fontSize={"16px"}
                          fontWeight={500}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#0D0D0D"
                              : "#f6f1f2"
                          }
                          margin={"8px 0px"}
                        >
                          Select the time amount
                        </Typography>

                        <Stack flexDirection={"row"} gap={"8px"}>
                          <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.98 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 17,
                            }}
                            style={{ width: "33%" }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                                border: "1px solid",
                                borderColor:
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "transparent"
                                    : "#43474E",
                                borderColor:
                                  buyTime == "60"
                                    ? "#8DA8FF!important"
                                    : "transparent!important",
                                borderRadius: "16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background:
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#F8F8FA"
                                    : "#191919",
                              }}
                              height={"68px"}
                              padding={"20px"}
                              onClick={() => handleChangeTime("60")}
                            >
                              <Typography
                                fontSize={"14px"}
                                lineHeight={"14px"}
                                fontWeight={400}
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#0D0D0D"
                                    : "#f6f1f2"
                                }
                                textAlign={"center"}
                              >
                                1 hour
                              </Typography>
                            </Box>
                          </motion.div>
                          <motion.div
                            className="box"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.98 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 17,
                            }}
                            style={{ width: "33%" }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                                border: "1px solid",
                                borderColor:
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "transparent"
                                    : "#43474E",
                                borderColor:
                                  buyTime == "120"
                                    ? "#8DA8FF!important"
                                    : "transparent!important",
                                borderRadius: "16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background:
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#F8F8FA"
                                    : "#191919",
                              }}
                              height={"68px"}
                              padding={"20px"}
                              onClick={() => handleChangeTime("120")}
                            >
                              <Typography
                                fontSize={"14px"}
                                lineHeight={"14px"}
                                fontWeight={400}
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#0D0D0D"
                                    : "#f6f1f2"
                                }
                                textAlign={"center"}
                              >
                                2 hours
                              </Typography>
                            </Box>
                          </motion.div>
                          <motion.div
                            className="box"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.98 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 17,
                            }}
                            style={{ width: "33%" }}
                          >
                            <Box
                              sx={{
                                cursor: "pointer",
                                border: "1px solid",
                                borderColor:
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "transparent"
                                    : "#43474E",
                                borderColor:
                                  buyTime == "180"
                                    ? "#8DA8FF!important"
                                    : "transparent!important",
                                borderRadius: "16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background:
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#F8F8FA"
                                    : "#191919",
                              }}
                              height={"68px"}
                              padding={"20px"}
                              onClick={() => handleChangeTime("180")}
                            >
                              <Typography
                                fontSize={"14px"}
                                lineHeight={"14px"}
                                fontWeight={400}
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#0D0D0D"
                                    : "#f6f1f2"
                                }
                                textAlign={"center"}
                              >
                                3 hours
                              </Typography>
                            </Box>
                          </motion.div>
                        </Stack>

                        <Typography
                          marginTop={2}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#676768"
                              : "#b1b1b2"
                          }
                          fontSize={"14px"}
                          fontWeight={400}
                        >
                          Rate:{" "}
                          {leaderboardStore ? leaderboardStore.minerRate : ""}{" "}
                          /sec
                        </Typography>
                      </Stack>
                      <Stack width={["100%", "50%"]}>
                        <Typography
                          fontSize={"16px"}
                          fontWeight={700}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#0D0D0D"
                              : "#f6f1f2"
                          }
                          margin={"8px 0px"}
                        >
                          Summary
                        </Typography>

                        <Box
                          padding={"16px"}
                          bgcolor={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#f8f8fa"
                              : "#191919"
                          }
                          borderRadius={"16px"}
                          display={"flex"}
                          gap={"16px"}
                          flexDirection={"column"}
                        >
                          <Stack
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              fontSize={"14px"}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#676768"
                                  : "#b1b1b2"
                              }
                            >
                              {Number(buyTime) / 60}{" "}
                              {buyTime <= 60 ? "hour" : "hours"}
                            </Typography>
                            <Typography
                              fontSize={"14px"}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#676768"
                                  : "#b1b1b2"
                              }
                            >
                              {price.toFixed(6) + " CNTP"}
                            </Typography>
                          </Stack>
                          <Stack
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                          >
                            {selectedProfile &&
                              Number(selectedProfile.tokens?.cCNTP?.balance) >
                              Number(price) ? (
                              // gas-fee message
                              <>
                                <Typography
                                  fontSize={"14px"}
                                  color={
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#676768"
                                      : "#b1b1b2"
                                  }
                                >
                                  GAS fee
                                </Typography>
                                {!isLoadingGasFee ? (
                                  <Typography
                                    fontSize={"14px"}
                                    color={
                                      localStorage.getItem("mui-mode") ===
                                        "light"
                                        ? "#676768"
                                        : "#b1b1b2"
                                    }
                                  >
                                    {" "}
                                    {gasFee} CONET
                                  </Typography>
                                ) : (
                                  <Skeleton
                                    variant="rectangular"
                                    width={"55px"}
                                    height={21}
                                  />
                                )}
                              </>
                            ) : (
                              // insufficient-cCNTP-funds message
                              <Typography>
                                <span style={{ fontWeight: "bold" }}>
                                  You don't have enough CNTP
                                </span>
                                <br />
                                Earn more tokens before you purchase!
                              </Typography>
                            )}
                          </Stack>

                          <Divider />

                          <Stack
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                          >
                            {selectedProfile &&
                              Number(selectedProfile?.tokens?.cCNTP?.balance) >
                              Number(price) ? (
                              <>
                                <Typography
                                  fontSize={"14px"}
                                  fontWeight={700}
                                  color={
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#0d0d0d"
                                      : "#f6f1f2"
                                  }
                                >
                                  Total
                                </Typography>
                                <Box flexDirection={"row"} display={"flex"}>
                                  <Typography
                                    fontSize={"14px"}
                                    textAlign={"right"}
                                    fontWeight={700}
                                    color={
                                      localStorage.getItem("mui-mode") ===
                                        "light"
                                        ? "#0d0d0d"
                                        : "#f6f1f2"
                                    }
                                  >
                                    {price.toFixed(6).toString() + " CNTP + "}
                                  </Typography>
                                  {!isLoadingGasFee ? (
                                    <Typography
                                      fontSize={"14px"}
                                      textAlign={"right"}
                                      fontWeight={700}
                                      color={
                                        localStorage.getItem("mui-mode") ===
                                          "light"
                                          ? "#0d0d0d"
                                          : "#f6f1f2"
                                      }
                                    >
                                      {Number(gasFee).toFixed(6).toString() +
                                        " CONET"}
                                    </Typography>
                                  ) : (
                                    <Skeleton
                                      variant="rectangular"
                                      width={"65px"}
                                      margin={"0px 4px"}
                                      height={21}
                                    />
                                  )}
                                </Box>
                              </>
                            ) : (
                              <></>
                            )}
                          </Stack>
                        </Box>
                        <motion.div
                          className="box"
                          whileHover={{ scale: 1.01 }}
                          whileTap={{ scale: 0.98 }}
                          transition={{
                            type: "spring",
                            stiffness: 400,
                            damping: 17,
                          }}
                        >
                          {!isMiningRunning ? (
                            <PurchaseButtom
                              style={{
                                width: "100%",
                                textAlign: "center",
                                margin: "0 auto",
                                height: "70px",
                                marginTop: "8px",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "600",
                                fontSize: "15px",
                                padding: "0.65rem",
                                borderRadius: "0.75rem",
                                cursor: "pointer",
                                backgroundColor: circleDevider,
                              }}
                              disabled={
                                !leaderboardStore || isLoading || isProcessingSilentPassBuy || (
                                  selectedProfile &&
                                  Number(selectedProfile.tokens?.cCNTP?.balance) <
                                  Number(price)
                                )
                              }
                              //disabled={false}
                              onClick={handlePurchase}
                            >
                              {!isLoading && !isProcessingSilentPassBuy && leaderboardStore ? (
                                `Burn ${Number(price).toFixed(
                                  4
                                )} $CNTP to use ${Number(buyTime) / 60}  ${Number(buyTime) <= 60 ? "hour" : "hours"
                                }`
                              ) : (
                                <CircularProgress size={24} color="inherit" />
                              )}
                            </PurchaseButtom>
                          ) : (
                            <PurchaseButtom
                              style={{
                                width: "100%",
                                textAlign: "center",
                                margin: "0 auto",
                                height: "70px",
                                marginTop: "8px",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "600",
                                fontSize: "15px",
                                padding: "0.65rem",
                                borderRadius: "0.75rem",
                                cursor: "pointer",
                                backgroundColor: circleDevider,
                              }}
                              disabled={
                                selectedProfile &&
                                Number(selectedProfile.tokens?.cCNTP?.balance) <
                                Number(price)
                              }
                              //disabled={false}
                              onClick={() => stopMining()}
                            >
                              Stop mining and Burn CNTP
                            </PurchaseButtom>
                          )}
                        </motion.div>
                      </Stack>
                    </Stack>

                    <Box display={"flex"} gap={"8px"} flexBasis={"50%"}>
                      {/* Select Entry Region */}
                      <Stack flexBasis={"inherit"}>
                        <Typography
                          fontSize={"16px"}
                          fontWeight={500}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#0D0D0D"
                              : "#f6f1f2"
                          }
                          margin={"8px 0px"}
                        >
                          Select Entry Region
                        </Typography>
                        <Stack
                          flexDirection={["column", "row"]}
                          justifyContent={["flex-start", "space-between"]}
                          gap={"8px"}
                        >
                          <Stack width={"100%"}>
                            <motion.nav
                              initial={false}
                              animate={
                                isEntryNodeSelectOpen ? "open" : "closed"
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <motion.div
                                whileTap={{ scale: 0.97 }}
                                onClick={() =>
                                  setIsEntryNodeSelectOpen((state) => !state)
                                }
                                style={{
                                  width: "100%",
                                  backgroundColor:
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#f8f8fa"
                                      : "#191919",
                                  borderRadius: "16px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "16px 24px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={`https://flagsapi.com/${selectedEntryRegion}/flat/24.png`}
                                  />
                                  <Typography
                                    color={
                                      localStorage.getItem("mui-mode") ===
                                        "light"
                                        ? "#0D0D0D"
                                        : "#f6f1f2"
                                    }
                                  >
                                    {locationMap[selectedEntryRegion]}
                                  </Typography>
                                </div>

                                <motion.div
                                  variants={{
                                    open: { rotate: 180 },
                                    closed: { rotate: 0 },
                                  }}
                                  transition={{ duration: 0.05 }}
                                  style={{ originY: 0.55 }}
                                >
                                  <SvgIcon
                                    component={KeyboardArrowDownIcon}
                                    inheritViewBox
                                    sx={{ fontSize: 16 }}
                                  />
                                </motion.div>
                              </motion.div>
                              <Box
                                height={isEntryNodeSelectOpen ? "220px" : "0px"}
                              >
                                <motion.ul
                                  variants={{
                                    open: {
                                      clipPath: "inset(0% 0% 0% 0% round 10px)",
                                      transition: {
                                        type: "spring",
                                        bounce: 0,
                                        duration: 0.7,
                                        delayChildren: 0.3,
                                        staggerChildren: 0.05,
                                      },
                                    },
                                    closed: {
                                      clipPath:
                                        "inset(10% 50% 90% 50% round 10px)",
                                      transition: {
                                        type: "spring",
                                        bounce: 0,
                                        duration: 0.3,
                                      },
                                    },
                                  }}
                                  style={{
                                    pointerEvents: isEntryNodeSelectOpen
                                      ? "auto"
                                      : "none",
                                    maxHeight: "100%",
                                    overflow: "scroll",
                                    backgroundColor:
                                      localStorage.getItem("mui-mode") ===
                                        "light"
                                        ? "#f8f8fa"
                                        : "#191919",
                                    marginTop: "8px",
                                  }}
                                >
                                  {nodeLocation &&
                                    nodeLocation.length > 0 &&
                                    nodeLocation.map(
                                      (region: string, i: number) => {
                                        return (
                                          <motion.li
                                            variants={itemVariants}
                                            style={{
                                              display: "flex",
                                              gap: "16px",
                                              marginTop: "8px",
                                              cursor: "pointer",
                                              paddingLeft: "24px",
                                            }}
                                            onClick={() => {
                                              setIsEntryNodeSelectOpen(false);
                                              handleEntryRegionChange(region);
                                            }}
                                          >
                                            <img
                                              src={`https://flagsapi.com/${region}/flat/24.png`}
                                            />
                                            <Typography>
                                              {locationMap[region]}
                                            </Typography>
                                          </motion.li>
                                        );
                                      }
                                    )}
                                </motion.ul>
                              </Box>
                            </motion.nav>
                          </Stack>
                        </Stack>
                      </Stack>

                      {/* Select Egress Region */}
                      <Stack flexBasis={"inherit"}>
                        <Typography
                          fontSize={"16px"}
                          fontWeight={500}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#0D0D0D"
                              : "#f6f1f2"
                          }
                          margin={"8px 0px"}
                        >
                          Select Exit Region
                        </Typography>
                        <Stack
                          flexDirection={["column", "row"]}
                          justifyContent={["flex-start", "space-between"]}
                          gap={"8px"}
                        >
                          <Stack width={"100%"}>
                            <motion.nav
                              initial={false}
                              animate={
                                isEgressNodeSelectOpen ? "open" : "closed"
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <motion.div
                                whileTap={{ scale: 0.97 }}
                                onClick={() =>
                                  setIsEgressNodeSelectOpen((state) => !state)
                                }
                                style={{
                                  width: "100%",
                                  backgroundColor:
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#f8f8fa"
                                      : "#191919",
                                  borderRadius: "16px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "16px 24px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={`https://flagsapi.com/${selectedEgressRegion}/flat/24.png`}
                                  />
                                  <Typography
                                    color={
                                      localStorage.getItem("mui-mode") ===
                                        "light"
                                        ? "#0D0D0D"
                                        : "#f6f1f2"
                                    }
                                  >
                                    {locationMap[selectedEgressRegion]}
                                  </Typography>
                                </div>

                                <motion.div
                                  variants={{
                                    open: { rotate: 180 },
                                    closed: { rotate: 0 },
                                  }}
                                  transition={{ duration: 0.05 }}
                                  style={{ originY: 0.55 }}
                                >
                                  <SvgIcon
                                    component={KeyboardArrowDownIcon}
                                    inheritViewBox
                                    sx={{ fontSize: 16 }}
                                  />
                                </motion.div>
                              </motion.div>

                              <Box
                                height={
                                  isEgressNodeSelectOpen ? "220px" : "0px"
                                }
                              >
                                <motion.ul
                                  variants={{
                                    open: {
                                      clipPath: "inset(0% 0% 0% 0% round 10px)",
                                      transition: {
                                        type: "spring",
                                        bounce: 0,
                                        duration: 0.7,
                                        delayChildren: 0.3,
                                        staggerChildren: 0.05,
                                      },
                                    },
                                    closed: {
                                      clipPath:
                                        "inset(10% 50% 90% 50% round 10px)",
                                      transition: {
                                        type: "spring",
                                        bounce: 0,
                                        duration: 0.3,
                                      },
                                    },
                                  }}
                                  style={{
                                    pointerEvents: isEgressNodeSelectOpen
                                      ? "auto"
                                      : "none",
                                    maxHeight: "100%",
                                    overflow: "scroll",
                                    backgroundColor:
                                      localStorage.getItem("mui-mode") ===
                                        "light"
                                        ? "#f8f8fa"
                                        : "#191919",
                                    marginTop: "8px",
                                  }}
                                >
                                  {nodeLocation &&
                                    nodeLocation.length > 0 &&
                                    nodeLocation.map(
                                      (region: string, i: number) => {
                                        return (
                                          <motion.li
                                            variants={itemVariants}
                                            style={{
                                              display: "flex",
                                              gap: "16px",
                                              marginTop: "8px",
                                              cursor: "pointer",
                                              paddingLeft: "24px",
                                            }}
                                            onClick={() => {
                                              setIsEgressNodeSelectOpen(false);
                                              handleEgressRegionChange(region);
                                            }}
                                          >
                                            <img
                                              src={`https://flagsapi.com/${region}/flat/24.png`}
                                            />
                                            <Typography>
                                              {locationMap[region]}
                                            </Typography>
                                          </motion.li>
                                        );
                                      }
                                    )}
                                </motion.ul>
                              </Box>
                            </motion.nav>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                ) : (
                  // node users
                  <>
                    <Box
                      display={"flex"}
                      flexDirection={["column", "row"]}
                      width={"100%"}
                      justifyContent={"space-between"}
                      alignItems={"initial"}
                      gap={"16px"}
                      marginTop={["20px", "0px"]}
                    >
                      {/* Select Regions */}
                      <Box
                        flex={1}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={"8px"}
                      >
                        {/* Select Entry Region */}
                        <Box>
                          <Typography
                            fontSize={"16px"}
                            fontWeight={500}
                            color={
                              localStorage.getItem("mui-mode") === "light"
                                ? "#0D0D0D"
                                : "#f6f1f2"
                            }
                            margin={"8px 0px"}
                          >
                            Select the Entry Region
                          </Typography>

                          <Stack width={"100%"}>
                            <motion.nav
                              initial={false}
                              animate={
                                isEntryNodeSelectOpen ? "open" : "closed"
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <motion.div
                                whileTap={{ scale: 0.97 }}
                                onClick={() =>
                                  setIsEntryNodeSelectOpen((state) => !state)
                                }
                                style={{
                                  width: "100%",
                                  backgroundColor:
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#f8f8fa"
                                      : "#191919",
                                  borderRadius: "16px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "16px 24px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={`https://flagsapi.com/${selectedEntryRegion}/flat/24.png`}
                                  />
                                  <Typography
                                    color={
                                      localStorage.getItem("mui-mode") ===
                                        "light"
                                        ? "#0D0D0D"
                                        : "#f6f1f2"
                                    }
                                  >
                                    {locationMap[selectedEntryRegion]}
                                  </Typography>
                                </div>

                                <motion.div
                                  variants={{
                                    open: { rotate: 180 },
                                    closed: { rotate: 0 },
                                  }}
                                  transition={{ duration: 0.05 }}
                                  style={{ originY: 0.55 }}
                                >
                                  <SvgIcon
                                    component={KeyboardArrowDownIcon}
                                    inheritViewBox
                                    sx={{ fontSize: 16 }}
                                  />
                                </motion.div>
                              </motion.div>
                              <Box
                                height={isEntryNodeSelectOpen ? "220px" : "0px"}
                              >
                                <motion.ul
                                  variants={{
                                    open: {
                                      clipPath: "inset(0% 0% 0% 0% round 10px)",
                                      transition: {
                                        type: "spring",
                                        bounce: 0,
                                        duration: 0.7,
                                        delayChildren: 0.3,
                                        staggerChildren: 0.05,
                                      },
                                    },
                                    closed: {
                                      clipPath:
                                        "inset(10% 50% 90% 50% round 10px)",
                                      transition: {
                                        type: "spring",
                                        bounce: 0,
                                        duration: 0.3,
                                      },
                                    },
                                  }}
                                  style={{
                                    pointerEvents: isEntryNodeSelectOpen
                                      ? "auto"
                                      : "none",
                                    maxHeight: "100%",
                                    overflow: "scroll",
                                    backgroundColor:
                                      localStorage.getItem("mui-mode") ===
                                        "light"
                                        ? "#f8f8fa"
                                        : "#191919",
                                    marginTop: "8px",
                                  }}
                                >
                                  {nodeLocation &&
                                    nodeLocation.length > 0 &&
                                    nodeLocation.map(
                                      (region: string, i: number) => {
                                        return (
                                          <motion.li
                                            variants={itemVariants}
                                            style={{
                                              display: "flex",
                                              gap: "16px",
                                              cursor: "pointer",
                                              paddingLeft: "24px",
                                            }}
                                            onClick={() => {
                                              setIsEntryNodeSelectOpen(false);
                                              handleEntryRegionChange(region);
                                            }}
                                          >
                                            <img
                                              src={`https://flagsapi.com/${region}/flat/24.png`}
                                            />
                                            <Typography>
                                              {locationMap[region]}
                                            </Typography>
                                          </motion.li>
                                        );
                                      }
                                    )}
                                </motion.ul>
                              </Box>
                            </motion.nav>
                          </Stack>
                        </Box>

                        {/* Select Egress Region */}
                        <Box>
                          <Typography
                            fontSize={"16px"}
                            fontWeight={500}
                            color={
                              localStorage.getItem("mui-mode") === "light"
                                ? "#0D0D0D"
                                : "#f6f1f2"
                            }
                            margin={"8px 0px"}
                          >
                            Select the Exit Region
                          </Typography>

                          <Stack width={"100%"}>
                            <motion.nav
                              initial={false}
                              animate={
                                isEgressNodeSelectOpen ? "open" : "closed"
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <motion.div
                                whileTap={{ scale: 0.97 }}
                                onClick={() =>
                                  setIsEgressNodeSelectOpen((state) => !state)
                                }
                                style={{
                                  width: "100%",
                                  backgroundColor:
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#f8f8fa"
                                      : "#191919",
                                  borderRadius: "16px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "16px 24px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={`https://flagsapi.com/${selectedEgressRegion}/flat/24.png`}
                                  />
                                  <Typography
                                    color={
                                      localStorage.getItem("mui-mode") ===
                                        "light"
                                        ? "#0D0D0D"
                                        : "#f6f1f2"
                                    }
                                  >
                                    {locationMap[selectedEgressRegion]}
                                  </Typography>
                                </div>

                                <motion.div
                                  variants={{
                                    open: { rotate: 180 },
                                    closed: { rotate: 0 },
                                  }}
                                  transition={{ duration: 0.05 }}
                                  style={{ originY: 0.55 }}
                                >
                                  <SvgIcon
                                    component={KeyboardArrowDownIcon}
                                    inheritViewBox
                                    sx={{ fontSize: 16 }}
                                  />
                                </motion.div>
                              </motion.div>
                              <Box
                                height={
                                  isEgressNodeSelectOpen ? "220px" : "0px"
                                }
                              >
                                <motion.ul
                                  variants={{
                                    open: {
                                      clipPath: "inset(0% 0% 0% 0% round 10px)",
                                      transition: {
                                        type: "spring",
                                        bounce: 0,
                                        duration: 0.7,
                                        delayChildren: 0.3,
                                        staggerChildren: 0.05,
                                      },
                                    },
                                    closed: {
                                      clipPath:
                                        "inset(10% 50% 90% 50% round 10px)",
                                      transition: {
                                        type: "spring",
                                        bounce: 0,
                                        duration: 0.3,
                                      },
                                    },
                                  }}
                                  style={{
                                    pointerEvents: isEgressNodeSelectOpen
                                      ? "auto"
                                      : "none",
                                    maxHeight: "100%",
                                    overflow: "scroll",
                                    backgroundColor:
                                      localStorage.getItem("mui-mode") ===
                                        "light"
                                        ? "#f8f8fa"
                                        : "#191919",
                                    marginTop: "8px",
                                  }}
                                >
                                  {nodeLocation &&
                                    nodeLocation.length > 0 &&
                                    nodeLocation.map(
                                      (region: string, i: number) => {
                                        return (
                                          <motion.li
                                            variants={itemVariants}
                                            style={{
                                              display: "flex",
                                              gap: "16px",
                                              cursor: "pointer",
                                              paddingLeft: "24px",
                                            }}
                                            onClick={() => {
                                              setIsEgressNodeSelectOpen(false);
                                              handleEgressRegionChange(region);
                                            }}
                                          >
                                            <img
                                              src={`https://flagsapi.com/${region}/flat/24.png`}
                                            />
                                            <Typography>
                                              {locationMap[region]}
                                            </Typography>
                                          </motion.li>
                                        );
                                      }
                                    )}
                                </motion.ul>
                              </Box>
                            </motion.nav>
                          </Stack>
                        </Box>
                        {/* Purchase Button */}
                        <Box flex={1}>
                          <PurchaseButtom
                            style={{
                              width: "100%",
                              textAlign: "center",
                              height: "55px",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "600",
                              fontSize: "15px",
                              padding: "0.65rem",
                              borderRadius: "0.75rem",
                              cursor: "pointer",
                              backgroundColor: circleDevider,
                            }}
                            disabled={false}
                            onClick={() => handleUpdateRegion()}
                          >
                            {!isLoadingRegionChange ? (
                              `Restart Silent Pass`
                            ) : (
                              <CircularProgress size={24} color="inherit" />
                            )}
                          </PurchaseButtom>
                        </Box>
                      </Box>
                    </Box>

                    {/* message */}
                    <Box
                      height={"236px"}
                      width={"100%"}
                      bgcolor={
                        localStorage.getItem("mui-mode") === "light"
                          ? "#f8f8fa"
                          : "#191919"
                      }
                      borderRadius={"16px"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={"24px"}
                    >
                      <Stack
                        display={"flex"}
                        direction={"row"}
                        gap={"8px"}
                        justifyContent={"center"}
                        alignContent={"center"}
                        alignItems={"center"}
                      >
                        <img src={guardianLogo} height={"48px"} />
                      </Stack>
                      <Stack>
                        <Typography
                          fontSize={"16px"}
                          fontWeight={500}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#0d0d0d"
                              : "#f6f1f2"
                          }
                          textAlign={"center"}
                        >
                          Enjoy your private network
                        </Typography>
                        <Typography
                          fontSize={"14px"}
                          fontWeight={400}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#676768"
                              : "#b1b1b2"
                          }
                          textAlign={"center"}
                        >
                          Free for Guardians
                        </Typography>
                      </Stack>
                    </Box>
                  </>
                )}
              </Stack>
            ) : (
              // silent pass already running
              <>
                <Box
                  display={"flex"}
                  flexDirection={["column", "row"]}
                  width={"100%"}
                  justifyContent={"space-between"}
                  alignItems={"initial"}
                  gap={"8px"}
                  marginTop={["20px", "0px"]}
                >
                  {/* Select Regions */}
                  <Box
                    flex={1}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"8px"}
                  >
                    {/* Select Entry Region */}
                    <Box>
                      <Typography
                        fontSize={"16px"}
                        fontWeight={500}
                        color={
                          localStorage.getItem("mui-mode") === "light"
                            ? "#0D0D0D"
                            : "#f6f1f2"
                        }
                        margin={"8px 0px"}
                      >
                        Select the Entry Region
                      </Typography>
                      <Stack width={"100%"}>
                        <motion.nav
                          initial={false}
                          animate={isEntryNodeSelectOpen ? "open" : "closed"}
                          style={{ cursor: "pointer" }}
                        >
                          <motion.div
                            whileTap={{ scale: 0.97 }}
                            onClick={() =>
                              setIsEntryNodeSelectOpen((state) => !state)
                            }
                            style={{
                              width: "100%",
                              backgroundColor:
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#f8f8fa"
                                  : "#191919",
                              borderRadius: "16px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "16px 24px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={`https://flagsapi.com/${selectedEntryRegion}/flat/24.png`}
                              />
                              <Typography
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#0D0D0D"
                                    : "#f6f1f2"
                                }
                              >
                                {locationMap[selectedEntryRegion]}
                              </Typography>
                            </div>

                            <motion.div
                              variants={{
                                open: { rotate: 180 },
                                closed: { rotate: 0 },
                              }}
                              transition={{ duration: 0.05 }}
                              style={{ originY: 0.55 }}
                            >
                              <SvgIcon
                                component={KeyboardArrowDownIcon}
                                inheritViewBox
                                sx={{ fontSize: 16 }}
                              />
                            </motion.div>
                          </motion.div>
                          <Box height={isEntryNodeSelectOpen ? "220px" : "0px"}>
                            <motion.ul
                              variants={{
                                open: {
                                  clipPath: "inset(0% 0% 0% 0% round 10px)",
                                  transition: {
                                    type: "spring",
                                    bounce: 0,
                                    duration: 0.7,
                                    delayChildren: 0.3,
                                    staggerChildren: 0.05,
                                  },
                                },
                                closed: {
                                  clipPath: "inset(10% 50% 90% 50% round 10px)",
                                  transition: {
                                    type: "spring",
                                    bounce: 0,
                                    duration: 0.3,
                                  },
                                },
                              }}
                              style={{
                                pointerEvents: isEntryNodeSelectOpen
                                  ? "auto"
                                  : "none",
                                maxHeight: "100%",
                                overflow: "scroll",
                                backgroundColor:
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#f8f8fa"
                                    : "#191919",
                                marginTop: "8px",
                              }}
                            >
                              {nodeLocation &&
                                nodeLocation.length > 0 &&
                                nodeLocation.map(
                                  (region: string, i: number) => {
                                    return (
                                      <motion.li
                                        variants={itemVariants}
                                        style={{
                                          display: "flex",
                                          gap: "16px",
                                          marginTop: "8px",
                                          cursor: "pointer",
                                          paddingLeft: "24px",
                                        }}
                                        onClick={() => {
                                          setIsEntryNodeSelectOpen(false);
                                          handleEntryRegionChange(region);
                                        }}
                                      >
                                        <img
                                          src={`https://flagsapi.com/${region}/flat/24.png`}
                                        />
                                        <Typography>
                                          {locationMap[region]}
                                        </Typography>
                                      </motion.li>
                                    );
                                  }
                                )}
                            </motion.ul>
                          </Box>
                        </motion.nav>
                      </Stack>
                    </Box>

                    {/* Select Egress Region */}
                    <Box>
                      <Typography
                        fontSize={"16px"}
                        fontWeight={500}
                        color={
                          localStorage.getItem("mui-mode") === "light"
                            ? "#0D0D0D"
                            : "#f6f1f2"
                        }
                        margin={"8px 0px"}
                      >
                        Select the Exit Region
                      </Typography>
                      <Stack width={"100%"}>
                        <motion.nav
                          initial={false}
                          animate={isEgressNodeSelectOpen ? "open" : "closed"}
                          style={{ cursor: "pointer" }}
                        >
                          <motion.div
                            whileTap={{ scale: 0.97 }}
                            onClick={() =>
                              setIsEgressNodeSelectOpen((state) => !state)
                            }
                            style={{
                              width: "100%",
                              backgroundColor:
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#f8f8fa"
                                  : "#191919",
                              borderRadius: "16px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "16px 24px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={`https://flagsapi.com/${selectedEgressRegion}/flat/24.png`}
                              />
                              <Typography
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#0D0D0D"
                                    : "#f6f1f2"
                                }
                              >
                                {locationMap[selectedEgressRegion]}
                              </Typography>
                            </div>

                            <motion.div
                              variants={{
                                open: { rotate: 180 },
                                closed: { rotate: 0 },
                              }}
                              transition={{ duration: 0.05 }}
                              style={{ originY: 0.55 }}
                            >
                              <SvgIcon
                                component={KeyboardArrowDownIcon}
                                inheritViewBox
                                sx={{ fontSize: 16 }}
                              />
                            </motion.div>
                          </motion.div>

                          <Box
                            height={isEgressNodeSelectOpen ? "220px" : "0px"}
                          >
                            <motion.ul
                              variants={{
                                open: {
                                  clipPath: "inset(0% 0% 0% 0% round 10px)",
                                  transition: {
                                    type: "spring",
                                    bounce: 0,
                                    duration: 0.7,
                                    delayChildren: 0.3,
                                    staggerChildren: 0.05,
                                  },
                                },
                                closed: {
                                  clipPath: "inset(10% 50% 90% 50% round 10px)",
                                  transition: {
                                    type: "spring",
                                    bounce: 0,
                                    duration: 0.3,
                                  },
                                },
                              }}
                              style={{
                                pointerEvents: isEgressNodeSelectOpen
                                  ? "auto"
                                  : "none",
                                maxHeight: "100%",
                                overflow: "scroll",
                                backgroundColor:
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#f8f8fa"
                                    : "#191919",
                                marginTop: "8px",
                              }}
                            >
                              {nodeLocation &&
                                nodeLocation.length > 0 &&
                                nodeLocation.map(
                                  (region: string, i: number) => {
                                    return (
                                      <motion.li
                                        variants={itemVariants}
                                        style={{
                                          display: "flex",
                                          gap: "16px",
                                          marginTop: "8px",
                                          cursor: "pointer",
                                          paddingLeft: "24px",
                                        }}
                                        onClick={() => {
                                          setIsEgressNodeSelectOpen(false);
                                          handleEgressRegionChange(region);
                                        }}
                                      >
                                        <img
                                          src={`https://flagsapi.com/${region}/flat/24.png`}
                                        />
                                        <Typography>
                                          {locationMap[region]}
                                        </Typography>
                                      </motion.li>
                                    );
                                  }
                                )}
                            </motion.ul>
                          </Box>
                        </motion.nav>
                      </Stack>
                    </Box>

                    {/* Purchase Button */}
                    <Box flex={1}>
                      <PurchaseButtom
                        style={{
                          width: "100%",
                          textAlign: "center",
                          height: "55px",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontSize: "15px",
                          padding: "0.65rem",
                          borderRadius: "0.75rem",
                          cursor: "pointer",
                          backgroundColor: circleDevider,
                        }}
                        disabled={false}
                        onClick={() => handleUpdateRegion()}
                      >
                        {!isLoadingRegionChange ? (
                          `Restart Silent Pass`
                        ) : (
                          <CircularProgress size={24} color="inherit" />
                        )}
                      </PurchaseButtom>
                    </Box>
                  </Box>
                </Box>

                {/* message  */}
                <Box
                  marginTop={"8px"}
                  width={"100%"}
                  padding={"16px"}
                  height={"280px"}
                  bgcolor={
                    localStorage.getItem("mui-mode") === "light"
                      ? "#f8f8fa"
                      : "#191919"
                  }
                  borderRadius={"16px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    width={"50%"}
                    marginTop={"8px"}
                    padding={"16px"}
                    borderRadius={"16px"}
                    sx={{
                      backgroundColor:
                        localStorage.getItem("mui-mode") === "light"
                          ? "#CDD8FF"
                          : "#363E59",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      color={
                        localStorage.getItem("mui-mode") === "light"
                          ? "#17181F"
                          : "#F6F8FF"
                      }
                      textAlign={"center"}
                    >
                      Your silent pass is still running. Please wait for it to
                      finish to get more time
                    </Typography>
                  </Box>
                </Box>
              </>
            )
          ) : (
            // insufficient cCNTP funds
            <Box
              marginTop={"8px"}
              width={"100%"}
              padding={"16px"}
              height={"280px"}
              bgcolor={
                localStorage.getItem("mui-mode") === "light"
                  ? "#f8f8fa"
                  : "#191919"
              }
              borderRadius={"16px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box
                width={"50%"}
                marginTop={"8px"}
                padding={"16px"}
                borderRadius={"16px"}
                sx={{
                  backgroundColor:
                    localStorage.getItem("mui-mode") === "light"
                      ? "#CDD8FF"
                      : "#363E59",
                  cursor: "pointer",
                }}
              >
                {selectedProfile?.tokens?.cCNTP?.balance < 0.001 ? (
                  <Typography
                    color={
                      localStorage.getItem("mui-mode") === "light"
                        ? "#17181F"
                        : "#F6F8FF"
                    }
                    textAlign={"center"}
                  >
                    Insufficient cCNTP funds
                  </Typography>
                ) : (
                  <Typography
                    color={
                      localStorage.getItem("mui-mode") === "light"
                        ? "#17181F"
                        : "#F6F8FF"
                    }
                    textAlign={"center"}
                    onClick={() => unlockWallet()}
                  >
                    {isLoadingUnlock ? (
                      <CircularProgress color="success" size="1rem" />
                    ) : (
                      "Unlock account"
                    )}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </Box>

        {/*                 {
                    unlock && true && ( //seconds > 0
                        <Box marginTop={'8px'} width={'100%'} padding={'16px'} height={'280px'} bgcolor={localStorage.getItem('mui-mode') === 'light' ? "#f8f8fa" : "#191919"} borderRadius={'16px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Box width={'50%'} marginTop={'8px'} padding={'16px'} borderRadius={'16px'} sx={{ backgroundColor: localStorage.getItem('mui-mode') === 'light' ? "#CDD8FF" : "#363E59", cursor: 'pointer' }}>
                                <Typography color={localStorage.getItem('mui-mode') === 'light' ? "#17181F" : "#F6F8FF"} textAlign={'center'}>You have already bought silent pass</Typography>
                            </Box>
                        </Box>
                    )
                } */}

        {/* Silent Pass tutorials */}
        <Stack
          style={{
            marginTop: "16px",
            display: "flex",
            flexDirection: "column",
            rowGap: "16px",
            maxWidth: "100%",
          }}
        >
          <Typography
            fontSize={"16px"}
            marginBottom={"18px"}
            fontWeight={400}
            color={
              localStorage.getItem("mui-mode") === "light" ? "#0D0D0D" : "#fff"
            }
          >
            How to set up
          </Typography>
          <Card
            hasContent={true}
            title={"Download the Daemon"}
            index={0}
            content={
              <Box
                style={{
                  width: "100%",
                  backgroundColor:
                    localStorage.getItem("mui-mode") === "light"
                      ? "#f8f8fa"
                      : "#191919",
                  borderBottomLeftRadius: "16px",
                  borderBottomRightRadius: "16px",
                  marginTop: "-10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "16px 24px",
                }}
              >
                <Box width={"100%"}>
                  <DownloadArea />

                  <OnlyMobileText
                    variant="h6"
                    color="text.neutral"
                    marginTop={"20px"}
                    sx={{
                      textAlign: "center",
                      fontSize: "0.8rem",
                    }}
                  >
                    "Currently only support with desktop computers",
                  </OnlyMobileText>
                </Box>
              </Box>
            }
          />

          <Card title={"Start Silent Pass on the platform"} index={1} />

          <Card
            hasContent={true}
            title={"Configure the proxy"}
            index={2}
            content={
              <Box
                style={{
                  width: "100%",
                  backgroundColor:
                    localStorage.getItem("mui-mode") === "light"
                      ? "#f8f8fa"
                      : "#191919",
                  borderBottomLeftRadius: "16px",
                  borderBottomRightRadius: "16px",
                  marginTop: "-10px",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "30px",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  padding: "16px 24px",
                }}
              >
                {/* Select proxy option */}
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  padding={"0 0 0 40px"}
                  width={"100%"}
                >
                  <Box style={{ scrollbarWidth: "thin", overflowX: "auto" }}>
                    <ToggleButtonGroup
                      value={selectedProxyOption}
                      exclusive
                      onChange={handleProxyOptionChange}
                      aria-label="proxy option"
                      size="small"
                      color="primary"
                    >
                      {silentpassConfig.map((config, index) => (
                        <ToggleButton
                          style={{ padding: "15px" }}
                          key={index}
                          value={index}
                        >
                          {config.title}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Box>
                </Box>

                {/* Steps */}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Box
                    style={{
                      overflowX: "scroll",
                      scrollbarWidth: "auto",
                      marginLeft: "50px",
                    }}
                  >
                    <Box style={{ width: "100%" }}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: "32px",
                        }}
                      >
                        {silentpassConfig[selectedProxyOption].steps.map(
                          (step, stepIndex) => (
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                rowGap: "8px",
                                maxWidth: "600px",
                              }}
                              key={stepIndex}
                            >
                              <h4>
                                {stepIndex + 1}. {step.text}
                              </h4>
                              <img src={step.image} alt="" />
                            </Box>
                          )
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
