import create from "zustand";


interface ILeaderboards {
    leaderboards: any
    wallets: any[]
    referees: any[]
    oracleAssets: any
    setLeaderboards: (leaderboards: any) => void
    setWallets: (wallets: any[]) => void
    setReferees: (referees: any[]) => void
    setOracleAssets: (oracleAssets: any) => void
}


export const useRefreshStore = create<ILeaderboards>((set) => ({
    leaderboards: undefined,
    wallets: [],
    referees: [],
    oracleAssets: undefined,
    setLeaderboards: (leaderboards: any) => set({ leaderboards }),
    setWallets: (wallets: any) => set({ wallets }),
    setReferees: (referees: any) => set({ referees }),
    setOracleAssets: (oracleAssets: any) => set({ oracleAssets })
}));
