'use client';
import { useEffect, useState } from 'react'
import './styles.css';

import conetian from './assets/conetian.png';
import { getCookie, setCookie } from '../../../utilities/cookies';

const cname: '_conet_conetian_game_popup' = '_conet_conetian_game_popup';
const expire_days = 1;

export default function ConetianPopup() {
  const [shouldOpen, setShouldOpen] = useState<boolean>(false);

  useEffect(() => {
    const conetCookie = getCookie(cname);

    if (!conetCookie) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setShouldOpen(true);
      setCookie(cname, "true", expire_days);
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
    }
  }, [])

  function closeModal() {
    setShouldOpen(false);
    document.body.style.height = 'auto';
    document.body.style.overflow = 'auto';
  }

  if (!shouldOpen) return <></>;

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="conet-bg modal-container black-stroke" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <div className="modal-text">
            <div>
              <h1 className="modal-title">The CoNETian</h1>
              <h2 className="modal-subtitle">Play-2-Earn</h2>
            </div>
            <img src={conetian} alt="Conetian" className="mobile-modal-image" />
            <p className="modal-description">Earn $CNTP easily in the FIRST Mining Mini-Game app on Telegram</p>
            <a className="modal-button" href="https://t.me/conetian_bot" target="_blank">Play and Mine</a>
          </div>
          <div className="modal-image-container">
            <img src={conetian} alt="Conetian" className="modal-image" />
          </div>
          <button className="modal-close-button" onClick={closeModal}>X</button>
        </div>
        <div className="modal-background buildings-bg"></div>
      </div>
    </div>
  )
}
