import { useIntl } from "react-intl";
import { Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import { useState, useEffect } from "react";
import { Button } from "@mui/material-next";
import { useTheme } from "styled-components";
import { IoIosArrowForward } from "react-icons/io";

import { getWorkerService } from "../../../../services/workerService/workerService";
import { SuperNodeBanner } from "./pags/SuperNodeBanner";
import DashBoardpanel from "./pags/DashBoardpanel";
import { BoostBanner } from "./pags/BoostBanner";
import { Community } from "./pags/Community";
import {
  ContentWrapper,
  InternalHeader,
  InternalHeaderWelcome,
} from "./styles/index";

const currentProfile = () => {
  const workerService = getWorkerService();
  if (workerService.data.status === "LOCKED") {
    return null;
  }
  // const index = workerService.data.profiles.findIndex((n: any) => {
  //   return n.isPrimary;
  // });

  return workerService.data;
};

const Miner = () => {
  const intl = useIntl();
  const theme = useTheme();

  const [showNodeMiner, setShowNodeMiner] = useState(true);
  const [cntp, setCntp] = useState("0");
  const [todayCntp, setTodayCntp] = useState("0");

  useEffect(() => {
    const fetchData = () => {
      if (!active) {
        return;
      }
      const profile = currentProfile();
      setCntp(profile.tokens?.CNTP?.balance);
    };

    let active = true;
    fetchData();
    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    const element = document.getElementById(`welcome`);
    if (!element) return;

    element.innerHTML = element.innerHTML.replace(
      "CONET",
      `<span style="color: ${theme.ui.colors.primary}; font-weight: 600;">CONET</span>`
    );
  }, []);

  return (
    <ContentWrapper>
      <InternalHeader width="100%">
        <InternalHeaderWelcome>
          <Typography
            id="welcome"
            variant="h4"
            sx={{ fontWeight: "500", fontSize: `2.5rem` }}
          >
            {intl.formatMessage({ id: "platform.miner.header.title" })}
          </Typography>

          <Link
            target="_blank"
            href={
              "https://scan.conet.network/address/0xa4b389994A591735332A67f3561D60ce96409347"
            }
          >
            <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">

              <Typography
                sx={{
                  color: theme.ui.colors.primary,
                  fontWeight: `500`,
                  fontSize: `14px`,
                }}
              >
                {intl.formatMessage({
                  id: "platform.miner.header.title.smartContract",
                })}
              </Typography>
              <IoIosArrowForward fontSize="14px" />
            </Stack>
          </Link>
        </InternalHeaderWelcome>
        <DashBoardpanel />
      </InternalHeader>

      <Grid
        item
        md={12}
        sm={8}
        sx={{ textAlign: "center", width: "100%", padding: "5rem 0 3rem 0" }}
      >
        <Community
          CNTP={todayCntp}
          setCNTP={setCntp}
          setTodayCNTP={setTodayCntp}
        />
      </Grid>

      {!showNodeMiner && (
        <Grid
          item
          md={12}
          sm={8}
          xs={4}
          sx={{ textAlign: "center", width: "100%", padding: "5rem 0 3rem 0" }}
        >
          <Button
            variant="elevated"
            onClick={() => setShowNodeMiner(true)}
            sx={{ fontFamily: "inherit" }}
          >
            {intl.formatMessage({ id: "platform.miner.register.title" })}
          </Button>
        </Grid>
      )}

      <SuperNodeBanner />
      <BoostBanner />

    </ContentWrapper>
  );
};

export default Miner;
